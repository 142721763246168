(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

module.exports = function () {
    var ajaxDisableQueue = [];

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $(document).ajaxStart(function () {
        var focused = $(':focus');
        ajaxDisableQueue.push(focused);
        if (typeof focused !== typeof undefined && focused.length > 0) {
            focused.addClass('disabledElement');
        }
    });

    $(document).ajaxComplete(function (event, request, settings) {
        var focused = ajaxDisableQueue.shift();
        if (typeof focused !== typeof undefined && focused.length > 0) {
            focused.removeClass('disabledElement');
        }
    });

    $(document).ajaxError(function (event, xhr, ajaxSettings, thrownError) {
        var message = require('../alerts/message');
        switch (xhr.status) {
            case 0:
                //Cancelled, do nothing
                break;
            case 418:
            case 401:
                message.show(true, Lang.get('errors.ajax.401'));
                break;
            case 403:
                message.show(true, Lang.get('errors.ajax.403'));
                break;
            case 404:
                message.show(true, Lang.get('errors.ajax.404'));
                break;
            default:
                message.show(true, Lang.get('errors.ajax.default') + " " + xhr.status);
        }
    });
};

},{"../alerts/message":6}],2:[function(require,module,exports){
'use strict';

module.exports = function () {

    $(document).on('click', 'a[data-ajax], button[data-ajax]', function (e) {
        var selector = $(this).attr('data-target');
        var url = $(this).attr('href');
        var addToUrl = $(this).is('[data-update-url]');
        var method = $(this).attr('data-method') || 'GET';
        var extraData = $(this).attr('data-extra-data') || '';
        var confirm = $(this).attr('data-confirm');
        e.preventDefault();
        if (confirm) {
            bootbox.dialog({
                message: confirm,
                title: Lang.get('global.Confirm'),
                buttons: {
                    danger: {
                        label: Lang.get('global.No'),
                        className: "btn-link",
                        callback: function callback() {}
                    },
                    success: {
                        label: Lang.get('global.Yes'),
                        className: "btn blue",
                        callback: function callback() {
                            doAjaxCall(selector, url, method, extraData);
                        }
                    }
                }
            });
        } else {
            doAjaxCall(selector, url, method, extraData);
        }
    });
    var Form = require('../forms/models/Form');
    $(document).on('change', 'form[data-autocommit] input', function (e) {
        var form = new Form($(this).closest('form'));
        form.submitAjax({
            autosave: true,
            saved_item: $(this).attr('name')
        });
    });

    var doAjaxCall = function doAjaxCall(selector, url, method, extraData) {
        $.ajax({
            url: url,
            method: method,
            data: extraData,
            success: function success(response) {
                jsonResponseParser.parse(response);

                console.log(response);
                if (response.message) {
                    $(selector).html(response.view);
                    $('.messages').html(response.message).show().delay(4000).hide(500);
                } else {
                    $(selector).html(response);
                }
                if (response.removeElement) {
                    $(response.removeElement).remove();
                }
                if (response.fillElement) {
                    $(response.fillElementSelector).html(response.fillElement);
                }
                if (response.redirectTo) {
                    console.log(response.redirectTo);
                    window.location = response.redirectTo;
                }
                var Modal = require('../modals/modal.js');
                var modal = new Modal('.modal');
                modal.resize();

                Metronic.initAjax();

                var Summernote = require('../forms/summernote.js').Summernote();
                Summernote.init();
            }
        });
    };
};

},{"../forms/models/Form":15,"../forms/summernote.js":18,"../modals/modal.js":32}],3:[function(require,module,exports){
'use strict';

module.exports = function () {
    var Modal = require('../modals/modal');

    $(document).on('click', 'a[data-toggle="inline"]', function (e) {
        if ($(this).data('update-url')) {
            window.history.pushState({}, '', $(this).data('update-url'));
        }
        var selector = $(this).attr('data-target');

        /**
         * If the attribute data-loading-text is available
         * make this the inner value of the link/button
         */
        if ($(this).attr('data-loading-text') != '') {
            $(this).attr('original_text', $(this).text());
            $(this).text($(this).attr('data-loading-text'));
        }

        $.get($(this).attr('href'), function (data) {
            $(selector).html(data);
            if ($(this).attr('original_text') != '') {
                $(this).text($(this).attr('original_text'));
            }
            var modal = new Modal('.modal');
            modal.resize();
        });
        e.preventDefault();
    });
};

},{"../modals/modal":32}],4:[function(require,module,exports){
'use strict';

module.exports = {
    load: function load(itemInRow) {
        $(itemInRow).parents('tr').find('.progress-icon').addClass('fa fa-spinner fa-spin').dequeue().stop().css({ 'display': 'inline-block', 'color': 'gray', 'opacity': 100 });
    },

    finish: function finish(itemInRow) {
        $(itemInRow).parents('tr').find('.progress-icon').removeClass('fa-spinner fa-spin fa-exclamation-triangle').addClass('fa fa-check').css({ 'color': '#91C400' }).delay(3000).fadeOut(1000);
    },
    failed: function failed(itemInRow) {
        var error = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

        $(itemInRow).parents('tr').find('.progress-icon').removeClass('fa-spinner fa-spin fa-check').addClass('fa fa-exclamation-triangle').attr('title', error).css({ 'color': 'red' }).delay(3000).fadeOut(1000);
    }
};

},{}],5:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('click', '.header-alert-item', function () {
        new HeaderAlert().removeSingle($(this));
    });

    $(document).on('click', '.delete-all-alerts', function () {
        new HeaderAlert().removeAll($(this));
    });

    function HeaderAlert() {
        this.noOfAlertsBadge = $('#number-of-alerts');

        this.remove = function (element) {
            element.hide();
            var numOfVisibleRows = element.closest('ul').find('li:visible').length;
            if (numOfVisibleRows === 2) {
                $('#header_alerts_bar').hide();
            } else {
                this.noOfAlertsBadge.html(this.noOfAlertsBadge.html() - 1);
            }
        };

        this.removeSingle = function (link) {
            var listItem = link.closest('li');
            this.remove(listItem);
        };

        this.removeAll = function (deleteAllLink) {
            var me = this;
            deleteAllLink.closest('ul.dropdown-menu').find('li.header-alert-item').each(function () {
                me.remove($(this));
            });
            $.ajax({
                method: 'POST',
                url: '/alerts/close-all'
            });
        };
    }
};

},{}],6:[function(require,module,exports){
/* failed = false means success message */
'use strict';

var show = function show(failed, message) {
    var messageDiv = $('.messages');
    messageDiv.html(message);
    messageDiv.removeClass('alert-danger alert-success').dequeue().stop();
    if (failed) {
        if (typeof failed.toLowerCase === 'function' && (failed.toLowerCase() == 'warning' || failed.toLowerCase() == 'warn')) {
            messageDiv.addClass('alert-warning');
        } else {
            messageDiv.addClass('alert-danger');
        }
    } else {
        messageDiv.addClass('alert-success');
    }
    messageDiv.stop(false, true).fadeIn(300).delay(10000).fadeOut(2000);
};

$(document).on('click', '.messages .close', function () {
    $(this).parents('.messages').hide();
});

module.exports = {
    show: show
};

},{}],7:[function(require,module,exports){
'use strict';

module.exports = function (alertIds) {
    /**
     * Use setTimeout to execute function in background after most other actions are done
     * Expecially when having many alerts, this function can slow down the application a bit
     * With putting this function in the background, everything wil keep going fast.
     */
    setTimeout(function () {
        if (alertIds.length === 0) {
            $('#number-of-alerts').hide();
        } else {
            $('#number-of-alerts').html(alertIds.length);
        }
        $('a.header-alert-link[data-alert-id]').each(function () {
            if (alertIds.indexOf($(this).data('alert-id')) === -1) {
                $(this).hide();
            }
        });
        $('a.overview-alert-link[data-alert-id]').each(function () {
            if (alertIds.indexOf($(this).data('alert-id')) === -1) {
                $(this).find('.badge').hide();
            }
        });
    }, 500);
};

},{}],8:[function(require,module,exports){
"use strict";

module.exports = function () {

    window.skipAudio = function (id) {
        var amount = arguments.length <= 1 || arguments[1] === undefined ? 15 : arguments[1];

        try {
            var oAudio = document.getElementById(id);
            oAudio.currentTime += amount;
        } catch (e) {
            console.error("Error:" + e);
        }
    };

    window.skipAudioTo = function (id, time) {
        try {
            var oAudio = document.getElementById(id);
            oAudio.currentTime = time;
        } catch (e) {
            console.error("Error:" + e);
        }
    };

    window.rewindAudio = function (id) {
        var amount = arguments.length <= 1 || arguments[1] === undefined ? 15 : arguments[1];

        return skipAudio(id, -1 * amount);
    };
};

},{}],9:[function(require,module,exports){
'use strict';

module.exports = function () {

    $(document).on('click', '.copy-baseprogram-tasks', function () {
        var reloadPage = $(this).data('reload');
        $.ajax({
            url: '/base-programs/' + coco.baseProgramId + '/copy',
            type: 'POST',
            data: {
                'weekFrom': $(this).data('from'),
                'weekTo': $(this).data('to')
            },
            success: function success(message) {
                $('.messages').html(message).show().removeClass('alert-danger').addClass('alert-success').delay(4000).hide(500);
                if (typeof reloadPage != typeof undefined) {
                    location.reload();
                }
            }
        });
    });

    $(document).on('click', '.delete-baseprogram-tasks', function () {
        $.ajax({
            url: '/base-programs/' + coco.baseProgramId + '/delete-week',
            type: 'POST',
            data: {
                'week': coco.week
            },
            success: function success(message) {
                $('.messages').html(message).show().removeClass('alert-danger').addClass('alert-success').delay(4000).hide(500);
                location.reload();
            }
        });
    });
};

},{}],10:[function(require,module,exports){
'use strict';

module.exports = function () {

    $(document).on('change', 'input:file', function () {
        var file = this.files[0];
        if (file) {
            var fileSize = file.size;
            var fileType = file.type;
            var errorDiv = $(this).closest('div');
            var mb = Math.ceil(fileSize / 1024 / 1024);

            $('#currentFileSize').html(' (' + mb + ' Mb.)');
            if ($(this).data('file-type') == 'image' && fileType.indexOf('image/') !== 0) {
                showErrorMessage(errorDiv, Lang.get('global.InvalidImage'));
            } else if (fileSize > parseInt(coco.uploadMaxFileSize)) {

                showErrorMessage(errorDiv, Lang.get('exercises.MaxUploadSizeInfo') + mb + "Mb.");
            } else {
                errorDiv.find('.alert-error').remove();
            }
        }
    });

    var showErrorMessage = function showErrorMessage(errorDiv, message) {
        errorDiv.find('.alert-error').remove();
        errorDiv.append('<div class="col-md-12 alert alert-error alert-danger"> <button class="close" data-close="alert"></button> <span>' + message + '</span> </div>');
        setTimeout(function () {
            errorDiv.find('.alert-error').fadeOut(300, function () {
                $(this).remove();
            });
        }, 30000);
    };
};

},{}],11:[function(require,module,exports){
'use strict';

module.exports = function () {
    var Modal = require('../modals/modal');
    var Form = require('./models/Form');

    /**
     * The form[data-remote] submits the form via ajax instead of a full post request
     * The special attributes of the form:
     *  - data-close-after: Close the modal after the ajax request has finished
     *
     *  The response (json):
     *   - json.errors: key value pairs of errors
     *   - json.function:
     *      * 'added':
     *          - json.elements: an array of elements to add, an element is an object with 2 variables:
     *              * selector: Where to add the element
     *              * htmlToAdd: Which html to add here
     *          - OR: json.htmlSelector and json.htmlToAdd when only a single element is added
     *          - json.deleteSelector: which field to remove to remove the "There is nothing" text
     *      *'removed':
     *          - json.rowSelector: An selector which row to remove, if not given the row with the clicked submit button is removed
     *          - json.noRowsHtml: The html to show when there are no rows left
     *      *'updated':
     *          json.rowSelector: Which row to update (if not given, if not given the row with the clicked submit button is updated
     *          json.updatedRow: the new html
     *  - json.message if json.failed an error message is shown, else an success message
     */
    $(document).on('submit', 'form[data-remote]', function (e) {
        e.preventDefault();
        var form = new Form($(this));
        form.submitAjax();
    });

    $(document).on('change', 'form.autosave, form.autosave input, form.autosave select , form.autosave textarea', function (e) {
        if ($(this).attr('name')) {
            var form = new Form($(this).closest('form'));
            form.submitAjax({ 'autosave': true, 'saved_item': $(this).attr('name') });
        }
    });

    var activeSave;
    $(document).on('keyup', 'textarea[data-autocommit]', function (e) {
        var area = $(this);
        if (activeSave != undefined) {
            clearTimeout(activeSave);
        }
        if (typeof ajaxMessage !== typeof undefined) {
            ajaxMessage.load();
        }
        activeSave = setTimeout(function () {
            var form = new Form(area.closest('form'));
            form.submitAjax();
            activeSave = undefined;
        }, 1500);
    });

    $(document).on('submit', 'form[data-toggle="inline"]', function (e) {
        var selector = $(this).attr('data-target');
        var form = $(this);
        var modal = new Modal(form.closest('.modal'));
        if (typeof ajaxMessage !== typeof undefined) {
            ajaxMessage.load();
        }
        e.preventDefault();
        var confirm = $(this).attr('data-confirm');
        if (confirm) {
            bootbox.dialog({
                message: confirm,
                title: Lang.get('global.Confirm'),
                buttons: {
                    danger: {
                        label: Lang.get('global.No'),
                        className: "btn-link",
                        callback: function callback() {}
                    },
                    success: {
                        label: Lang.get('global.Yes'),
                        className: "btn blue",
                        callback: function callback() {
                            doAjaxCall(selector, form, modal);
                        }
                    }
                }
            });
        } else {
            doAjaxCall(selector, form, modal);
        }
    });

    var doAjaxCall = function doAjaxCall(selector, form, modal) {
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            data: form.serialize(), // data to be submitted
            success: function success(response) {
                if (response.nameNotUnique) {
                    form.find('input[name="name"]').closest('div').append('<span class="error help-block" style="color: #a94442;">' + response.nameNotUnique + '.</span>');
                    return;
                }
                if (typeof ajaxMessage !== typeof undefined) {
                    ajaxMessage.divElem.hide();
                }
                if (response.message) {
                    $(selector).html(response.view);
                    $('.messages').html(response.message).show().delay(4000).hide(500);
                } else {
                    $(selector).html(response);
                }
                if (form.data('close-after') === 1) {
                    modal.hide();
                }
                if (response.fillElement) {
                    $(response.fillElementSelector).html(response.fillElement);
                }
                modal.resize();
            }
        });
    };

    $(document).on('shown.bs.modal', '.modal', function () {
        $(this).find('[autofocus]').focus();
    });
};

},{"../modals/modal":32,"./models/Form":15}],12:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('click', 'input[data-confirm], button[data-confirm]', function (e) {
        e.preventDefault();
        var input = $(this);
        bootbox.dialog({
            message: input.data('confirm'),
            title: Lang.get('global.Confirm'),
            buttons: {
                danger: {
                    label: Lang.get('global.No'),
                    className: "btn-link",
                    callback: function callback() {}
                },
                success: {
                    label: Lang.get('global.Yes'),
                    className: "btn blue",
                    callback: function callback() {
                        input.focus();
                        input.closest('form').submit();
                    }
                }
            }
        });
    });
};

},{}],13:[function(require,module,exports){
'use strict';

module.exports = function () {

    $(document).on('click', 'input.toggle-multiple-checkboxes', function (e) {
        var classToToggle = $(this).attr('data-class-to-toggle');
        var isChecked = $(this).is(":checked");
        if (classToToggle) {
            var checkboxes = $('.' + classToToggle);
            checkboxes.prop("checked", isChecked);
            if (isChecked) {
                checkboxes.parent().addClass('checked');
            } else {
                checkboxes.parent().removeClass('checked');
            }
        } else {
            checkboxes = $(this).closest('table').find('tbody').find("input:checkbox");
            checkboxes.prop("checked", isChecked);
            if (isChecked) {
                checkboxes.parent().addClass('checked');
            } else {
                checkboxes.parent().removeClass('checked');
            }
        }
    });

    // specific code for the overview of contentItems at patient homework tab
    // toggles delete button 'disabled' class looking at whether least one checkbox is checked, or none is.
    $(document).on('click', '#content-items-overview input[type=checkbox]', function (e) {
        if ($(this).is(":checked")) {
            $('#submit-delete-many').removeClass('disabled');
        } else {
            if ($(this).closest('table').find('tbody').find("input:checkbox").is(":checked") == false) {
                $('#submit-delete-many').addClass('disabled');
            }
        }
    });

    $(document).on('click', '.remember-in-session', function () {
        $.ajax({
            method: 'POST',
            url: '/session/put',
            data: { key: $(this).data('key'), value: $(this).prop('checked') }
        });
    });
};

},{}],14:[function(require,module,exports){
'use strict';

module.exports = function () {
    var feedbackForm = $("#feedbackForm");
    if (feedbackForm.css('display') === 'none') {
        feedbackForm.slideDown("slow");
    } else {
        feedbackForm.slideUp("fast");
    }
};

},{}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var Form = (function () {
    function Form(formElement) {
        _classCallCheck(this, Form);

        this.formElement = formElement;
        this.method = formElement.find('input[name="_method"]').val() || 'POST';
        this.submitButton = formElement.find("button[type=submit]:focus");
        this.action = formElement.prop('action');
        this.data = formElement.serialize();
        this.reset();
    }

    _createClass(Form, [{
        key: 'reset',
        value: function reset() {
            this.formElement.find('.form-group.has-error .error').remove();
            this.formElement.find('.form-group.has-error').removeClass('has-error');
        }
    }, {
        key: 'submitAjax',
        value: function submitAjax(options) {
            options = $.extend({
                'autosave': false
            }, options);
            var formInstance = this;
            var formData = new FormData(this.formElement[0]);
            formData.append('autosave', options['autosave']);
            if (options['autosave']) {
                formData.append('saved_item', options.saved_item);
            }
            $.ajax({
                type: this.method == 'PUT' || this.method == 'DELETE' ? 'POST' : this.method,
                url: this.action,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                beforeSend: function beforeSend() {
                    if (typeof ajaxMessage !== typeof undefined) {
                        ajaxMessage.load();
                    }
                    if (options['autosave'] && typeof autoSaveIcon !== typeof undefined) {
                        autoSaveIcon.load($('[name="' + options.saved_item + '"]'));
                    }
                },
                success: function success(json) {
                    formInstance.parseJson(json);
                    $(document).trigger('after-submit', [formInstance.formElement[0].id]);
                    Metronic.initAjax();
                    if (options['autosave'] && typeof autoSaveIcon !== typeof undefined) {
                        if (json.errors) {
                            autoSaveIcon.error($('[name="' + options.saved_item + '"]'));
                        } else {
                            autoSaveIcon.finish($('[name="' + options.saved_item + '"]'));
                        }
                    }
                },
                xhr: function xhr() {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                        // Check if upload property exists
                        $('progress').show();
                        myXhr.upload.addEventListener('progress', function (e) {
                            $('progress').attr({ value: e.loaded, max: e.total });
                        }, false); // For handling the progress of the upload
                    }
                    return myXhr;
                }
            });
        }
    }, {
        key: 'parseJson',
        value: function parseJson(data) {
            if (data.errors) {
                this.parseErrors(data.errors);
                if (typeof ajaxMessage !== typeof undefined) {
                    ajaxMessage.divElem.hide();
                }
                return 0;
            }
            if (typeof ajaxMessage !== typeof undefined) {
                ajaxMessage.finish();
            }

            if (this.formElement.data('close-after') === 1 || data.closeModal) {
                $('.modal').modal('hide');
                if (!this.formElement.data('keep-after-submit')) {
                    this.formElement[0].reset();
                }
            }

            if (data.resetForm) {
                this.formElement[0].reset();
                $(this.formElement[0]).find('span.checked').removeClass('checked');
                $("input[name=secondary_action]").val(0);
            }
            jsonResponseParser.submitButton = this.submitButton;
            jsonResponseParser.parse(data);
        }
    }, {
        key: 'parseErrors',
        value: function parseErrors(errorsByName) {
            for (var fieldName in errorsByName) {
                if (errorsByName.hasOwnProperty(fieldName)) {
                    var errors = errorsByName[fieldName];
                    this.formElement.find('*[name^="' + fieldName + '"]').parents('.form-group').addClass('has-error').append('<span class="error help-inline help-block col-md-offset-4 col-md-8" style="padding-left: 15px;">' + errors[0] + '</span>');
                }
            }
        }
    }]);

    return Form;
})();

exports['default'] = Form;
module.exports = exports['default'];

},{}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var TextArea = (function () {
    function TextArea(element, event) {
        _classCallCheck(this, TextArea);

        this.element = element;
        this.event = event;

        this.maxCharacters = $(element).data('max-characters');
        this.colorAfter = $(element).data('color-after');

        this.oldNumberOfCharacters = element.value.length;
        this.newNumberOfCharacters = this.oldNumberOfCharacters + TextArea.getCharacterChange(event.keyCode || event.charCode);
    }

    _createClass(TextArea, [{
        key: 'getsTooManyCharacters',
        value: function getsTooManyCharacters() {
            //deleting is always allowed
            if (TextArea.getCharacterChange(this.event.keyCode || this.event.charCode) == -1) {
                return false;
            }
            return this.newNumberOfCharacters > this.maxCharacters;
        }
    }, {
        key: 'getCharactersLeftColor',
        value: function getCharactersLeftColor() {
            if (this.maxCharacters - this.newNumberOfCharacters < this.colorAfter) {
                return 'red';
            }
            return 'black';
        }
    }, {
        key: 'getNumberOfCharactersLeft',
        value: function getNumberOfCharactersLeft() {
            if (this.getsTooManyCharacters()) {
                return this.maxCharacters - this.oldNumberOfCharacters;
            }
            return this.maxCharacters - this.newNumberOfCharacters;
        }
    }], [{
        key: 'getCharacterChange',
        value: function getCharacterChange(keyEventCode) {
            if (typeof keyEventCode == typeof undefined) {
                return 0;
            } else if (keyEventCode == 8 || keyEventCode == 46) {
                return -1;
            } else {
                return 1;
            }
        }
    }]);

    return TextArea;
})();

exports['default'] = TextArea;
module.exports = exports['default'];

},{}],17:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('change', '.check-dependants', function () {
        var showHide = $('div.change-permission[data-hide-on="' + $(this).val() + '"]');
        if (this.checked) {
            showHide.removeClass('hidden').find('.check-dependants').trigger('change');
        } else {
            showHide.addClass('hidden').find('.check-dependants').trigger('change');
        }
    });
};

},{}],18:[function(require,module,exports){
'use strict';

function Summernote() {

    //require('./summernote-plugins/image-upload')();

    var initDefault = function initDefault() {
        var summernoteDOM = $('.summernote');

        summernoteDOM.summernote({
            height: 250,
            toolbar: [['style', ['style', 'paragraph']], ['font', ['bold', 'italic', 'underline', 'clear']], ['layout', ['ul', 'ol']], ['insert', ['link', 'picture', 'video']]],
            lang: 'nl-NL'
        });

        var $styleBtn = $('.note-editor .note-toolbar');
        $styleBtn.find('[data-value="blockquote"]').parent().hide();
        $styleBtn.find('[data-value="pre"]').parent().hide();
        $styleBtn.find('[data-value="h4"]').parent().hide();
        $styleBtn.find('[data-value="h5"]').parent().hide();
        $styleBtn.find('[data-value="h6"]').parent().hide();
    };

    var initMessages = function initMessages() {
        var summernoteDOM = $('.summernote-message');
        summernoteDOM.summernote({
            height: 200,
            toolbar: [['font', ['bold', 'italic', 'underline', 'clear']], ['insert', ['link', 'picture']]]
        });
    };

    return {

        init: function init() {
            initMessages();
            initDefault();

            $(document).on('click', 'div.note-insert button', function () {
                $('.note-link-dialog .modal-dialog, .note-image-dialog .modal-dialog, .note-video-dialog .modal-dialog').removeClass('modal-dialog');
            });
        }
    };
}

module.exports = {
    Summernote: Summernote
};

},{}],19:[function(require,module,exports){
'use strict';

module.exports = function () {

    var TextArea = require('./models/TextArea');

    $(document).on('keydown', 'textarea[data-max-characters]', function (e) {
        var area = new TextArea(this, e);

        if (area.getsTooManyCharacters()) {
            e.preventDefault();
        }

        $('p.characters-left').css('color', area.getCharactersLeftColor());

        $('p.characters-left span').html(area.getNumberOfCharactersLeft());
    });
    $('textarea[data-max-characters]').trigger(jQuery.Event('keydown'));
};

},{"./models/TextArea":16}],20:[function(require,module,exports){
'use strict';

module.exports = function () {
    chart.addListener('clickGraphItem', function (event) {
        var message = $('div[data-confirmation-text]').attr('data-confirmation-text');
        bootbox.dialog({
            title: Lang.get('global.Confirm'),
            message: message,
            buttons: {
                danger: {
                    label: Lang.get('global.No'),
                    className: "btn-link",
                    callback: function callback() {}
                },
                success: {
                    label: Lang.get('global.Yes'),
                    className: "blue",
                    callback: function callback() {
                        $.ajax({
                            type: 'POST',
                            url: '/graded-activities/remove-value',
                            data: {
                                patient_id: coco.patientId,
                                field_name: event.target.valueField,
                                date: event.item.category
                            },
                            success: function success(data) {
                                jsonResponseParser.parse(data);
                                Metronic.initAjax();
                            }
                        });
                    }
                }
            }
        });
    });
    var hideGraphs = function hideGraphs() {
        for (var i in chart.graphs) {
            var graphItem = chart.graphs[i];
            var dataColumn = graphItem.valueField.replace(/([A-Z])/g, ' $1').split(' ')[0];
            if ($('#graded-activity-graph-settings').find('input[data-column="' + dataColumn + '"]').attr('checked') == 'checked') {
                chart.showGraph(graphItem);
            } else {

                chart.hideGraph(graphItem);
            }
        }
    };
    $(document).on('click', '#graded-activity-graph-settings input:checkbox', function () {
        hideGraphs();
    });
    hideGraphs();

    $(document).on('change', '#graded-activity-graph-settings .timespan select', function () {
        $.ajax({
            type: 'POST',
            url: '/graded-activities/update-graph',
            data: {
                patient_id: $('#graph-time-setting').data('patient-id'),
                graphFuturePast: $('#graph-future-past').val(),
                graphTimespan: $('#graph-timespan').val()
            },
            success: function success(data) {
                chart.dataProvider = data;
                chart.validateData();
            }
        });
    });

    //Fix for internet explorer/chrome on windows 7 for showing graph after resize
    $(".nav-tabs a").on("shown.bs.tab", function () {
        if (chart !== undefined) {
            chart.validateSize();
        }
    });
};

},{}],21:[function(require,module,exports){
'use strict';

module.exports = function () {
    var Modal = require('../modals/modal');
    $(document).on('click', '.help-instruction-video', function () {
        var modal = new Modal('#show-instruction-video', false);
        $('#instruction-video-frame').attr('src', $(this).data('video-url'));
        modal.open();
    });
};

},{"../modals/modal":32}],22:[function(require,module,exports){
"use strict";

module.exports = function () {
    $.fn.exists = function () {
        return this.length !== 0;
    };
};

},{}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var JsonResponseParser = (function () {
    function JsonResponseParser() {
        _classCallCheck(this, JsonResponseParser);
    }

    _createClass(JsonResponseParser, [{
        key: 'parse',
        value: function parse(response) {
            if (response.message) {
                var message = require('../alerts/message');
                message.show(response.failed, response.message);
            }

            if (response.elementsToAdd) {
                this.addHtml(response);
            }

            if (response.src) {
                eval(response.src);
            }

            if (response.event) {
                $(document).trigger(response.event.event, response.event.params);
            }

            this.removeHtml(response);
            this.updateHtml(response);

            if (response.reload) {
                location.reload();
            }

            if (response.redirectTo) {
                window.location = response.redirectTo;
            }

            if (response.replaceUrl) {
                $(response.replaceUrl.selector).attr('href', response.replaceUrl.url);
            }

            if (response.updateUrl) {
                window.history.pushState({}, '', response.updateUrl);
            }

            if (response.reloadTab) {
                JsonResponseParser.reloadTab(response.reloadTab);
            }
        }
    }, {
        key: 'addHtml',
        value: function addHtml(response) {
            $.each(response.elementsToAdd, function (index, element) {
                if (element.html && element.selector) {
                    JsonResponseParser.addElement($(element.selector), element.html, element.append);
                }
            });
        }
    }, {
        key: 'removeHtml',
        value: function removeHtml(response) {
            if (response.elementsToRemove) {
                $.each(response.elementsToRemove, function (index, element) {
                    if (element.selector) {
                        this.tableBody = $(element.selector).closest('tbody');
                        $(element.selector).remove();
                    }
                });
            }

            if (response.removeClickedRow) {
                this.tableBody = this.submitButton.closest('tbody');
                this.submitButton.closest('tr').remove();
            }

            if (response.removeElements) {
                $(response.removeElements).remove();
            }

            if (this.tableBody && response.noRowsHtml) {
                if (this.tableBody.find('tr').length === 0) {
                    JsonResponseParser.addElement(this.tableBody, response.noRowsHtml);
                }
            }
            if (response.noRows) {
                if ($(response.noRows.divSelector).find(response.noRows.itemSelector).length === 0) {
                    JsonResponseParser.addElement($(response.noRows.divSelector), response.noRows.html);
                }
            }
        }
    }, {
        key: 'updateHtml',
        value: function updateHtml(response) {
            if (response.replaceHtml) {
                if (response.replaceInner && response.replaceSelector) {
                    $(response.replaceSelector).html($.parseHTML(response.replaceHtml, document, true));
                } else if (response.replaceSelector) {
                    $(response.replaceSelector).replaceWith(response.replaceHtml);
                } else {
                    this.submitButton.closest('tr').replaceWith(response.replaceHtml);
                }
            }
        }
    }], [{
        key: 'reloadTab',
        value: function reloadTab(tabToReload) {
            var button = $('ul.nav').find('[href=' + tabToReload + ']');
            if (button.exists()) {
                var link = button.attr('data-url');
                $.get(link, function (response) {
                    $(tabToReload).html(response);
                });
            } else {
                location.reload();
            }
        }
    }, {
        key: 'addElement',
        value: function addElement(parent, html) {
            var append = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

            if (append) {
                parent.append($.parseHTML(html));
            } else {
                parent.before($.parseHTML(html));
            }
        }
    }]);

    return JsonResponseParser;
})();

exports['default'] = JsonResponseParser;
module.exports = exports['default'];

},{"../alerts/message":6}],24:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('click', 'span.list-overflow', function (e) {
        var target = $(this).attr('target');
        $("#" + target).removeClass('hidden');
        $(this).addClass('hidden');
        e.preventDefault();
    });
};

},{}],25:[function(require,module,exports){
'use strict';

module.exports = function () {
    $('tbody[data-sortable]').sortable({
        update: function update(event, ui) {
            var instanceIds = $('tbody[data-sortable] tr').map(function () {
                return this.getAttribute('data-instance-id');
            }).get();
            $.ajax({
                type: 'POST',
                url: $('tbody[data-sortable]').attr('data-url'),
                data: { 'order': instanceIds }
            });
        },
        placeholder: 'sortable-placeholder',
        handle: '.handle'
    }).disableSelection();
};

},{}],26:[function(require,module,exports){
/**
 * Generic functions
 */

//Jquery extension
'use strict';

require('./helpers/jqueryExtension')();

//All managed datatables
window.TableManaged = require('./tables/table-managed').TableManaged;

//Update the alerts in the header
window.updateAlerts = require('./alerts/updateAlerts');

//Show whether the ajax call of a row in a table is finished
window.ajaxIcon = require('./alerts/ajaxLoadingIconTable');

//Initialize summernote
window.Summernote = require('./forms/summernote.js').Summernote();

//the json parser
var JsonResponseParser = require('./json/JsonResponseParser');
window.jsonResponseParser = new JsonResponseParser();

/**
 * Generic listeners
 */

//Global ajax calls, ajax links and ajax tabs
require('./ajax/ajax')();
require('./ajax/ajaxLink')();
require('./ajax/loadAjax')();

//Close all alerts
require('./alerts/headerAlerts')();

//enable audio functions
require('./audio/audio.js')();

require('./forms/ajaxForm')();

//Global actions for buttons, at the moment only the confirm dialog
require('./forms/buttons')();

//Listeners for all pages where the 'select all' button is used
require('./forms/checkboxes')();
//Listeners for text area's, at the moment only for a maximum number of characters
require('./forms/textArea')();
//Show/hide fields depending on each other
require('./forms/showHideDependencies')();

//Listeners for checking for the right file upload an max upload size
require('./exercises/uploadVideo')();

//The show more button
require('./lists/overflow')();

//Show the new message modal
require('./messages/showNewMessageModal')();

//Show, resize, open and update modals
require('./modals/showModalListeners')();

//Show instruction video's
require('./help/showInstructionVideo')();

//Cache the navigation tabs
require('./navigation/tabs')();

//Jstree extension so there can be clicked on links in a jstree
require('./navigation/jstree')();

//Clickable row
require('./tables/clickRowLink')();

//Add function markAsCompleted to the window
require('./tasks/markAsCompleted')();

//Go to url when selecting an item from the list
require('./url/selectUrl')();

//hide items
require('./visibility/hide')();
require('./visibility/invisible')();

//Menu select
require('./url/menuSelect')();

/**
 * Specific Javascript
 */

//Base programs
window.copyBaseProgramWeeks = require('./base-programs/copyWeeks');

//feedback form
window.toggleFeedback = require('./forms/feedback');

//Graded activity
window.drawGraph = require('./graded-activity/graph');

//Sortable lists
window.initSortable = require('./lists/sortable');

//overview with patients table to show/hide the disabled patients
window.initPatientsTable = require('./patients/indexDatatable');
window.overlappingTeams = require('./patients/overlappingTeams');
//Update the alerts receiver with ajax
window.initAlertsReceiver = require('./patients/alertsReceiver');
window.shortlist = require('./patients/shortlist');

//override permissions for patient or professional
window.initOverridePermissionsTable = require('./patients/overridePermissions');

//Actions on the profile page
window.initScientificResearch = require('./profile/scientificResearch');
window.initEmailNotifications = require('./profile/toggleEmailNotifications');
window.initOverrideSettings = require('./profile/overrideSettings');

//Questionnaires
window.initRules = require('./questionnaires/rules');

//Target groups
window.initTargetGroups = require('./target-groups/targetGroups');

//Change the timeline of a patient
window.drawTree = require('./tasks/tree');
window.initTimeline = require('./tasks/timeline');

//Automatically select radio when entering a date for later
window.futureDates = require('./tasks/futureDate');

//patient training
window.dragDropTrainingPreviews = require('./training/listeners/dragDropTrainingPreviews');
window.trainingsPreview = require('./training/listeners/trainingPreview');
window.exerciseInstructions = require('./training/listeners/exerciseInstructions');
window.activateTrainingCheckboxes = require('./training/listeners/patient-detail-checkbox');
window.training = require('./training/listeners/training');
window.initTraining = require('./training/scripts/initTraining');
window.initCopyDay = require('./training/listeners/copyDay');
window.initPatientTraining = require('./training/listeners/patient-training');

//time table
window.initTImeTableActions = require('./time-table/time-table');

//base program exercise listeners
window.baseProgramListeners = require('./training/baseprograms/listeners');

//Patient executing training
window.initSkipExercise = require('./training/listeners/skipExercise');
window.additionalTraining = require('./training/scripts/additionalTraining');
window.initStopwatch = require('./training/scripts/stopwatch');

//The setting page
window.enableEditSettings = require('./settings/changeSetting');
window.activateTabs = require('./settings/settingTabs');

//Initialize reply form of a message
window.initializeReplyForm = require('./messages/hideShowReplyForm');

//log file
window.initLogFile = require('./tables/logfile');

window.Modal = require('./modals/modal');

//Attach Dropzone
window.attachDropZone = require('./messages/dropzone');

/**
 * Initialise metronic
 */
require('./main/init')();

},{"./ajax/ajax":1,"./ajax/ajaxLink":2,"./ajax/loadAjax":3,"./alerts/ajaxLoadingIconTable":4,"./alerts/headerAlerts":5,"./alerts/updateAlerts":7,"./audio/audio.js":8,"./base-programs/copyWeeks":9,"./exercises/uploadVideo":10,"./forms/ajaxForm":11,"./forms/buttons":12,"./forms/checkboxes":13,"./forms/feedback":14,"./forms/showHideDependencies":17,"./forms/summernote.js":18,"./forms/textArea":19,"./graded-activity/graph":20,"./help/showInstructionVideo":21,"./helpers/jqueryExtension":22,"./json/JsonResponseParser":23,"./lists/overflow":24,"./lists/sortable":25,"./main/init":27,"./messages/dropzone":28,"./messages/hideShowReplyForm":29,"./messages/showNewMessageModal":31,"./modals/modal":32,"./modals/showModalListeners":33,"./navigation/jstree":34,"./navigation/tabs":35,"./patients/alertsReceiver":36,"./patients/indexDatatable":37,"./patients/overlappingTeams":38,"./patients/overridePermissions":39,"./patients/shortlist":40,"./profile/overrideSettings":41,"./profile/scientificResearch":42,"./profile/toggleEmailNotifications":43,"./questionnaires/rules":44,"./settings/changeSetting":45,"./settings/settingTabs":46,"./tables/clickRowLink":47,"./tables/logfile":48,"./tables/table-managed":49,"./target-groups/targetGroups":50,"./tasks/futureDate":51,"./tasks/markAsCompleted":52,"./tasks/timeline":53,"./tasks/tree":54,"./time-table/time-table":55,"./training/baseprograms/listeners":57,"./training/listeners/copyDay":58,"./training/listeners/dragDropTrainingPreviews":59,"./training/listeners/exerciseInstructions":60,"./training/listeners/patient-detail-checkbox":61,"./training/listeners/patient-training":62,"./training/listeners/skipExercise":63,"./training/listeners/training":64,"./training/listeners/trainingPreview":65,"./training/scripts/additionalTraining":70,"./training/scripts/initTraining":73,"./training/scripts/stopwatch":74,"./url/menuSelect":75,"./url/selectUrl":77,"./visibility/hide":78,"./visibility/invisible":79}],27:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).ready(function () {
        Metronic.init(); // init metronic core components
        $('select.select2').select2({}); // init select2

        var Summernote = require('../forms/summernote.js').Summernote();
        Summernote.init();

        if ("coco" in window && !coco.isBehatZombie) {
            Layout.init(); // init current layout
        }
        UIExtendedModals.init();

        $('.js-tree').jstree();

        $('.datepicker').datepicker({
            format: "dd-mm-yyyy",
            language: Lang.get('dates.datepickerLocale'),
            autoclose: true,
            minDate: $(this).data('min-date'),
            maxDate: $(this).data('max-date')
        });

        setTimeout(function () {
            $('div.alert-success').fadeOut("slow");
        }, 8000);
    });
};

},{"../forms/summernote.js":18}],28:[function(require,module,exports){
'use strict';

module.exports = function () {
    Dropzone.autoDiscover = false;

    var myDropZone = new Dropzone('#fileDrop', {
        url: "/attachments/upload",
        clickable: true,
        maxFilesize: coco.uploadMaxFileSize / 1024 / 1024,
        dictDefaultMessage: Lang.get('messages.dropFilesHere'),
        dictRemoveFile: Lang.get('messages.removeFile'),
        addRemoveLinks: true,
        init: function init() {
            var id = $('input[name=_token]').val();
            var thisDropzone = this;

            $.ajax({
                type: "GET",
                url: '/attachments/upload/tmp',
                method: "GET",
                data: { "id": id },
                success: function success(data) {
                    if (data) {
                        $.each($.parseJSON(data), function (i, item) {
                            var mockFile = { name: item.filename, size: item.filesize };
                            thisDropzone.emit("addedfile", mockFile);
                            thisDropzone.createThumbnailFromUrl(mockFile, item.url);
                            thisDropzone.emit("complete", mockFile);
                        });
                    }
                }
            });
        },
        error: function error(file, message, xhr) {
            var messageBox = require('../alerts/message');

            console.log(message);
            messageBox.show(true, message);

            var thisDropzone = this;
            thisDropzone.removeFile(file);
        }

    });

    myDropZone.on('sending', function (file, xhr, formData) {
        var id = $('input[name=_token]').val();
        formData.append('id', id);
    });

    myDropZone.on('removedfile', function (file) {
        var id = $('input[name=_token]').val();

        $.ajax({
            type: "POST",
            url: '/attachments/upload/tmp',
            method: "DELETE",
            data: { "id": id, "filename": file.name }
        });
    });
};

},{"../alerts/message":6}],29:[function(require,module,exports){
'use strict';

module.exports = function () {

    $(document).on('click', '#add-reaction-button', function () {
        $('#show-form').show();
        $(this).hide();
    });
};

},{}],30:[function(require,module,exports){
'use strict';

function addableReceiversList() {

    function formatState(state) {
        if (state.element[0].className == 'favorite') {

            return $('<span><i class="fa fa-star" style="color: gold"></i> ' + state.text + '</span>');
        }
        return state.text;
    }

    var initDefault = function initDefault(limit) {
        $('select.select2').select2({
            formatSelectionTooBig: function formatSelectionTooBig() {
                return Lang.get('messages.CannotSelectMoreRecipients');
            },
            maximumSelectionSize: function maximumSelectionSize() {
                if (typeof limit != 'undefined') return limit;else return 100;
            },
            formatNoMatches: function formatNoMatches() {
                return Lang.get('messages.NoMoreOptions');
            },
            placeholder: $(this).data('placeholder'),
            allowClear: true,
            formatResult: formatState
        });
    };

    var setRecipients = function setRecipients(recipientsIds, aboutPatientId) {
        if (typeof recipientsIds != 'undefined') {
            $('#recipients').val(recipientsIds).trigger('change');
        }
        if (typeof aboutPatientId != 'undefined') {
            $('#messages-create-hidden-input').html($('<input value="' + aboutPatientId + '" name="aboutPatientId"' + ' type="hidden">'));
        }
    };

    var _setFixedRecipient = function _setFixedRecipient(userId, userName) {
        if (typeof userId != 'undefined') {
            $('#multiple-selector-recipients').remove();
            $('#messages-create-hidden-input').html($('<input value="' + userId + '" name="recipients[]"' + ' type="hidden">'));
            var upperCase = Lang.get('global.on').toLowerCase().replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase();
            });
            $('#messages-show-user-name').html($('<div class="form-group"><label for="Patient" class="col-md-3 col-xs-3 col-sm-3 control-label">' + upperCase + '</label><div class="col-md-7 col-xs-9 col-sm-9" style="font-size: 14px; margin-top: 8px">' + userName + '</div>'));
        }
    };

    return {

        init: function init(limit) {
            initDefault(limit);

            //if team get picked all other selections must reset and be limited to 1 selected, logs Uncaught TypeError: Cannot read property '0' of null
            $(document).on('change', '.select2', function (e) {
                if (typeof e.added !== 'undefined') {
                    if (e.added.id.indexOf("Team") > -1) {
                        $('#recipients').val([e.added.id]);
                        $(this).select2({
                            maximumSelectionSize: 1,
                            formatResult: formatState
                        });
                    } else {
                        $(this).select2({
                            maximumSelectionSize: 100,
                            formatResult: formatState
                        });
                    }
                }
            });

            $(document).on('submit', 'form.checkMinimalSelect', function (e) {
                var selectTeams = $(this).find('div.select-teams');
                if ($(this).find('li.select2-search-choice').length == 0) {
                    e.preventDefault();
                    var feedback = selectTeams.attr('data-feedback-minimal-select-one');
                    selectTeams.find('span#teams-validation-feedback').html('<span class="error help-block" style="color: #a94442">' + feedback + '</span>');
                } else {
                    selectTeams.find('span#teams-validation-feedback').html('');
                }
            });
        },

        selectRecipients: function selectRecipients(recipientsIds, aboutPatientId) {
            setRecipients(recipientsIds, aboutPatientId);
        },

        setFixedRecipient: function setFixedRecipient(userId, userName) {
            _setFixedRecipient(userId, userName);
        }
    };
}

module.exports = {
    addableReceiversList: addableReceiversList
};

},{}],31:[function(require,module,exports){
'use strict';

module.exports = function () {

    var Modal = require('../modals/modal');

    $(document).on('click', 'button[data-show-new-message]', function () {
        var button = $(this);

        var modal = new Modal('#place-show-message-create');
        modal.load('/messages/show-create-form', function () {
            var ids = String(button.data('recipients')).split(',').map(function (value) {
                return 'User_' + value;
            });
            $('#recipients').val(ids).select2();
        });
    });
};

},{"../modals/modal":32}],32:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var Modal = (function () {
    function Modal(target, closable, reloadOnClose) {
        _classCallCheck(this, Modal);

        this.target = target;
        if (typeof reloadOnClose !== 'undefined' ? reloadOnClose : false) {
            $(this.target).attr('data-reload-on-close', '1');
        }
    }

    _createClass(Modal, [{
        key: 'open',
        value: function open() {
            $(this.target).modal({
                'backdrop': 'static'
            });

            var datepicker = $('.datepicker');
            datepicker.datepicker({
                format: "dd-mm-yyyy",
                language: Lang.get('dates.datepickerLocale'),
                autoclose: true,
                minDate: datepicker.data('min-date'),
                maxDate: datepicker.data('max-date')
            });

            $('select.select2').select2({});
        }
    }, {
        key: 'hide',
        value: function hide() {
            $(this.target).modal('hide');
        }
    }, {
        key: 'refresh',
        value: function refresh() {
            this.modal.removeClass('fade').modal('hide');
            setTimeout(function () {
                $(this.target).modal('show').addClass('fade');
            }, 200);
        }
    }, {
        key: 'resize',
        value: function resize() {
            if (typeof window.activeModal !== typeof undefined) {
                window.setTimeout(function () {
                    var height = $(window).height();
                    activeModal.find(".modal-body").css({ "max-height": height - 160 + 'px', "overflow-y": "auto" });
                    var top = (height - activeModal.height() + 10) / 2;
                    if (top < 0) {
                        top = 0;
                    }
                    activeModal.css({ "margin-top": "0", "top": top + 'px' });
                    Metronic.initAjax();
                }, 5);
            }
        }
    }, {
        key: 'load',
        value: function load(url, fallback) {
            $.get(url, $.proxy(function (innerHtml) {
                $(this.target).html(innerHtml);
                if (!$(this.target).hasClass('modal')) {
                    this.target = $(this.target).find('.modal');
                }
                var Summernote = require('../forms/summernote.js').Summernote();
                Summernote.init();
                this.open();
                this.resize();
                if (typeof fallback !== typeof undefined) {
                    fallback();
                }
            }, this));
        }
    }]);

    return Modal;
})();

$(document).on('shown.bs.modal', new Modal('.modal').resize);
$(window).resize(new Modal('.modal').resize);

exports['default'] = Modal;
module.exports = exports['default'];

},{"../forms/summernote.js":18}],33:[function(require,module,exports){
'use strict';

module.exports = function () {
    var Modal = require('./modal');

    $(document).on('click', 'button[data-show-popup]', function () {
        var selector = $(this).data('target');
        var loadPopup = $(this).data('show-popup');
        var modal = new Modal(selector, $(this).data('static-backdrop'));
        if (loadPopup === 'load-ajax') {
            modal.load($(this).data('link'));
        } else {
            modal.open();
        }
    });

    $(document).on('hidden', '.modal[data-reset-on-close]', function () {
        if (($(this).data('modal') || { isShown: false }).isShown == false) {
            //Check if this modal is closed and not another modal inside this modal.
            var form = $(this).find('form');
            form[0].reset();
            form.find('select.select2').trigger('change');
            form.find('input:checkbox:not(:checked)').parent().removeClass('checked');
            form.find('input:checkbox:checked').parent().addClass('checked');
            form.find('.summernote').summernote("reset");
        }
    });

    $(document).on('shown', '.modal', function () {
        window.activeModal = $(this);
    });

    $(document).on('hidden', '.modal', function () {
        window.activeModal = $('.modal:visible');
        if ($(this).data('reload-on-close')) {
            location.reload();
            $(this).removeAttribute('data-reload-on-close');
        }
    });

    $(window).resize(function () {
        window.setTimeout(function () {
            var modal = new Modal('.modal');
            modal.resize();
        }, 20);
    });

    //TODO: This method contains specific code for teams and some other pages, make specific or use generic
    $(document).on('click', 'a[data-show-popup]', function (e) {
        e.preventDefault();

        var selector = $(this).data('target');
        var closable = $(this).data('static-backdrop');
        var reloadOnClose = $(this).data('reload-on-close');
        //TODO: Settings below are modal specific, this method has to be general...
        var aboutPatientId = $(this).data('about-patient-id');
        var recipientsIds = $(this).data('recipient-ids');
        if (typeof recipientsIds != 'undefined') {
            recipientsIds = recipientsIds.split(',');
        }
        var userId = $(this).data('user-id');
        var userName = $(this).data('user-name');
        var limit = $(this).data('limit');
        var tab = $(this).data('tab');
        var modal = new Modal(selector, closable, reloadOnClose);
        $.get($(this).attr('href'), function (data) {
            $(selector).html(data);
            modal.open();
            if (typeof document.createRange != typeof undefined) {
                var Summernote = require('../forms/summernote.js').Summernote();
                Summernote.init();
            }
            var addableReceiversList = require('../messages/selectReceivers.js').addableReceiversList();
            if (typeof userId != 'undefined') {
                addableReceiversList.setFixedRecipient(userId, userName);
            } else {
                addableReceiversList.init(limit);
                addableReceiversList.selectRecipients(recipientsIds, aboutPatientId);
            }
            if (tab) {
                $('.nav-tabs a[href="#' + tab + '"]').tab('show');
            }
        });
    });
};

},{"../forms/summernote.js":18,"../messages/selectReceivers.js":30,"./modal":32}],34:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('click', 'a.jstree-anchor', function () {
        window.location = $(this).attr('href');
    });

    $(document).on('click', 'li.jstree-node', function () {
        var modal = new Modal($(this).closest('.modal'));
        //Wait for the animation to finish
        setTimeout(function () {
            modal.resize();
        }, 200);
    });

    //Jstree conditional select
    (function ($, undefined) {
        "use strict";
        $.jstree.defaults.conditionalselect = function () {
            return false;
        };
        $.jstree.plugins.conditionalselect = function (options, parent) {
            // own function
            this.activate_node = function (obj, e) {
                if (this.settings.conditionalselect.call(this, this.get_node(obj))) {
                    parent.activate_node.call(this, obj, e);
                }
            };
        };
    })(jQuery);
    //end of jstree plugin
};

},{}],35:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('click', 'a[data-url]', function (e) {
        e.preventDefault();
        var url = $(this).data("url");
        var tabSelector = $(this).attr("href");
        var pane = $(this);
        var reload = $(this).data('reload');
        var deeplink = $(this).data('deeplink');

        if (typeof tabSelector !== typeof undefined && tabSelector.substring(0, 1) === "?") {
            tabSelector = tabSelector.substring(tabSelector.indexOf("#"));
        }
        window.history.pushState(url, "", pane.context.href);
        if ($(tabSelector).html().trim() === '' || reload) {
            if (pane.attr('disabled') !== 'disabled') {
                $.ajax({
                    url: url,
                    cache: true,
                    async: true,
                    type: "get",
                    dataType: "html",
                    beforeSend: function beforeSend() {
                        pane.addClass('disabledTab');
                        pane.parents('ul').find('a').each(function (index) {
                            $(this).addClass('disabledTab');
                        });
                        pane.append('<i id="spinner" class="fa fa-cog fa-spin"></i>');
                    },
                    success: function success(result) {
                        $(tabSelector).html(result);
                        if (deeplink) {
                            $(deeplink).tab('show');
                            $('.tab-deeplink').data('deeplink', deeplink);

                            setTimeout(function () {
                                window.dispatchEvent(new Event('resize'));
                            }, 100);
                        }
                    },
                    complete: function complete() {
                        $('#spinner').remove();
                        pane.parents('ul').find('a').each(function (index) {
                            $(this).removeClass('disabledTab');
                        });
                        pane.removeClass('disabledTab');
                    }
                });
            }
        }
        pane.tab('show');
    });

    $(document).on('click', 'a[data-tab-selector]', function (e) {
        var tabSelector = $(this).attr('data-tab-selector');
        $("ul#patient-tabs a[href='" + tabSelector + "']").trigger('click');
        e.preventDefault();
    });
};

},{}],36:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('change', '#alerts-receiver', function () {
        var dropdown = $(this);
        var alertReceiver = $(this).val();
        var patientId = coco.patientId;
        ajaxIcon.load(dropdown);

        $.ajax({
            method: 'POST',
            url: '/patients/edit-alerts-receiver/' + patientId,
            data: { alerts_receiver: alertReceiver },
            success: function success() {
                ajaxIcon.finish(dropdown);
            }
        });
    });
};

},{}],37:[function(require,module,exports){
'use strict';

module.exports = function () {

    setTimeout(function () {
        var table = $('#patients-index').DataTable();

        var runningAjaxId = null;

        function postTableVarsInSession() {
            clearTimeout(runningAjaxId);
            runningAjaxId = setTimeout(function () {
                $.ajax({
                    method: 'POST',
                    url: '/session/put',
                    data: {
                        key: 'table-patient-index-page',
                        value: {
                            page: table.page.info().page,
                            advancedSearch: $('#advanced-search').hasClass('active'),
                            filterShortlist: $('#filter-shortlist').prop('checked'),
                            filterDisabled: $('#filter-disabled').val(),
                            filterName: $('#filter-name').val(),
                            filterPatientNumber: $('#filter-patient-number').val(),
                            therapyLg: $('#therapy-loyalty-lg').is(':visible'),
                            therapyVal: $('#filter-therapy-loyalty').val(),
                            recentlyActive: $('#filter-recently-active').val(),
                            filterTargetGroups: $('#filter-target-groups').val(),
                            filterTeams: $('#filter-teams').val()
                        }
                    },
                    success: function success() {
                        table.draw();
                    }
                });
            }, 750);
        }

        $('#patients-index').on('draw.dt', function () {
            var json = table.ajax.json();
            var filterShortlist = $('#filter-shortlist').is(':checked');
            if (filterShortlist && json.iTotalDisplayRecords == json.iTotalRecords || !filterShortlist && json.iTotalDisplayRecords == json.activeUsers) {
                $('#filter-message').addClass('hidden');
            } else {
                $('#filter-message').removeClass('hidden');
            }
        });

        $(document).on('change', '#show-disabled-from', function () {
            var checked = $(this).prop('checked');
            $(this).closest('span').toggleClass('checked', checked);
            postTableVarsInSession();
        });

        //remove search
        $('#patients-index_filter').remove();

        /******************************\
         *         Advanced search        *
         \******************************/

        $(document).on('change', '#filter-shortlist', function () {
            var checked = $(this).prop('checked');
            $(this).closest('span').toggleClass('checked', checked);
            postTableVarsInSession();
        });

        //name
        $('#filter-name').on('keyup', function (e) {
            postTableVarsInSession();
        });

        //patient number
        $('#filter-patient-number').on('keyup', function (e) {
            postTableVarsInSession();
        });

        //therapy loyalty
        $('#therapy-loyalty-lg').on('click', function (e) {
            e.preventDefault();
            $(this).hide();
            $('.therapy-sm').show();
            postTableVarsInSession();
        });

        $('.therapy-sm').on('click', function (e) {
            e.preventDefault();
            $(this).hide();
            $('#therapy-loyalty-lg').show();
            postTableVarsInSession();
        });

        $('#filter-therapy-loyalty').on('keyup', function () {
            postTableVarsInSession();
        });

        //Recent actief
        $('#filter-recently-active').on('change', function () {
            postTableVarsInSession();
        });

        // Uitgeschreven
        $('#filter-disabled').on('change', function () {
            postTableVarsInSession();
        });

        //target groups
        $('#filter-target-groups').on('change', function () {
            postTableVarsInSession();
        });

        $('#filter-teams').on('change', function () {
            postTableVarsInSession();
        });
    }, 1);
};

},{}],38:[function(require,module,exports){
'use strict';

module.exports = function () {
    $('#teams').change(function () {
        $('#row-teams .error').remove();
        $.post('/patients/checkOverlappingTeams', { teams: $('#teams').val() }, function (data) {
            $('#row-teams').append(data);
        });
    });
};

},{}],39:[function(require,module,exports){
'use strict';

module.exports = function () {

    $("input.override-permission").bootstrapSwitch({
        size: 'mini',
        onText: 'Aan',
        offText: 'Uit',
        offColor: 'danger',
        onSwitchChange: function onSwitchChange(event, state) {
            var link = event.currentTarget;
            var value = state;
            var userId = coco.userId;
            var permissionId = link.dataset.permissionId;
            var permissionName = link.dataset.permissionName;

            $.ajax({
                method: 'GET',
                url: '/override-permission/' + userId,
                data: {
                    value: value,
                    permissionId: permissionId
                },
                success: function success(data) {
                    var message = require('../alerts/message');
                    if (value) {
                        message.show(false, data);
                        $('tr[data-depends-on="' + permissionId + '"]').removeClass('hidden');
                    } else {
                        message.show(false, data);
                        $('tr[data-depends-on="' + permissionId + '"]').addClass('hidden');
                    }
                    $('ul#patient-tabs').find('li[data-permission-name=' + permissionName + ']').toggle();
                    $('.load-ajax-tab').html('');
                }
            });
        }
    });
};

},{"../alerts/message":6}],40:[function(require,module,exports){
'use strict';

module.exports = function () {

    var xhr;
    $(document).on('click', 'img.star', function () {
        var method = 'POST';
        if ($(this).data('state') == 'unchecked') {
            $(this).data('state', 'checked');
            $(this).attr('src', '/images/star_checked.svg');
        } else {
            method = 'DELETE';
            $(this).data('state', 'unchecked');
            $(this).attr('src', '/images/star.svg');
        }
        if (xhr && xhr.readyState != 4) {
            xhr.abort();
        }
        xhr = $.ajax({
            url: '/shortlist/',
            type: method,
            data: {
                'patient_id': $(this).data('patient_id')
            }
        });
    });
};

},{}],41:[function(require,module,exports){
'use strict';

module.exports = function () {

    $(document).on('change', 'div#profile-settings .value-object-field', function (e) {
        var value = $(this).val();
        var groupedKey = $(this).attr('name');
        $.ajax({
            type: 'PUT',
            url: '/settings/override-setting/' + coco.userId,
            data: {
                groupedKey: groupedKey,
                value: value
            },
            success: function success(showThisMessage) {
                var message = require('../alerts/message');
                message.show(false, showThisMessage);
            }
        });
    });
};

},{"../alerts/message":6}],42:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('click', 'a.toggle-btn-set-colors', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var button = $(this);
        var title = button.data('title');
        var message = button.data('message');

        if (!button.hasClass('blue')) {

            if (typeof title !== typeof undefined) {
                bootbox.dialog({
                    message: button.data('message'),
                    title: button.data('title'),
                    buttons: {
                        success: {
                            label: Lang.get('global.Cancel'),
                            className: "lookLikeLink",
                            callback: function callback() {}
                        },
                        danger: {
                            label: Lang.get('global.Confirm'),
                            className: "blue",
                            callback: function callback() {
                                var removeClass = 'disabledElement';
                                button.addClass(removeClass);
                                doAjaxAndToggleBtnColors(url, button, removeClass);
                            }
                        }
                    }
                });
            } else {
                doAjaxAndToggleBtnColors(url, button);
            }
        }
    });

    var doAjaxAndToggleBtnColors = function doAjaxAndToggleBtnColors(url, button, removeClass) {
        $.ajax({
            url: url,
            type: "get",
            success: function success(result) {
                button.closest('div.btn-set').find('.toggle-btn-set-colors').removeClass('blue').addClass('grey');
                button.removeClass('grey').addClass('blue');
                var message = require('../alerts/message');
                message.show(result.failed, result.message);
                if (typeof removeClass !== typeof undefined) {
                    button.removeClass(removeClass);
                }
            }
        });
    };
};

},{"../alerts/message":6}],43:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('change', '#change-email-notifications', function (e) {
        $.ajax({
            type: 'PUT',
            url: '/settings/change-email-notifications',
            data: {
                notificationPreference: e.target.value,
                _token: $('input[name="_token"]').val(),
                userId: coco.userId
            },
            success: function success(showThisMessage) {
                var message = require('../alerts/message');
                message.show(false, showThisMessage);
            }
        });
    });

    $(document).on('change init', '#input-notifications-sms-notificationType', function () {
        if ($(this).val() == 'sms') {
            $('#setting-change-email-notification-preference').hide();
        } else {
            $('#setting-change-email-notification-preference').show();
        }
    });
    $('#input-notifications-sms-notificationType').trigger('init');
};

},{"../alerts/message":6}],44:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('change', '#identifier', function () {
        $.ajax({
            type: 'GET',
            url: '/rules/getInputForQuestion',
            data: {
                'questionnaireId': coco.questionnaireId,
                'ruleIdentifier': $(this).val()
            },
            success: function success(json) {
                $('#compareValues').html(json);
                Metronic.initAjax();
            }
        });
    });
};

},{}],45:[function(require,module,exports){
'use strict';

module.exports = function () {

    $(document).on('click', '.show_value input[type="submit"]', function () {
        $(this).parents('.show_value').hide().closest('td').find('.edit_value').show();
        Summernote.init();
    });

    var validateSetting = function validateSetting(field) {
        if (field.attr('type') == 'email') {
            var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            return re.test(field.val());
        }
        return true;
    };

    $(document).on('click', '.edit_value input[type="submit"]', function () {
        var saveButton = $(this);
        var parentEditSpan = saveButton.parents('.edit_value');
        var valueObjectField = parentEditSpan.find('.value-object-field');
        var value = valueObjectField.val();
        var jsonData = valueObjectField.data('json');
        var showValue = value;

        if (validateSetting(parentEditSpan.find('input')) === true) {
            if (jsonData) {
                showValue = jsonData[value];
            }

            ajaxIcon.load(parentEditSpan);
            $.ajax({
                type: 'PUT',
                url: 'settings/update',
                data: {
                    'settingId': saveButton.data('setting-id'),
                    'value': value
                },
                success: function success(response) {

                    if (response.hasOwnProperty('errors')) {
                        ajaxIcon.failed(parentEditSpan, response.errors);
                    } else {
                        ajaxIcon.finish(parentEditSpan);
                        parentEditSpan.hide().closest('td').find('.show_value').show().find('.value').html(showValue);
                    }
                }
            });
            parentEditSpan.find('input').css('background-color', 'white');
        } else {
            parentEditSpan.find('input').css('background-color', 'red');
        }
    });
};

},{}],46:[function(require,module,exports){
'use strict';

module.exports = function () {
    if (!window.location.hash) {
        $("#manage-settings a[href='#group-settings']").tab('show');
    }

    $(document).on('click', 'a[data-toggle="tab"]', function () {
        window.location.hash = $(this).attr('href');
    });
};

},{}],47:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('click touchstart', '.clickable-block', function (e) {
        var href = $(this).data('href');
        if (typeof href === typeof undefined) {
            href = $(this).parents('tr.clickable-row-data').data('href');
        }
        window.location = href;
    });
};

},{}],48:[function(require,module,exports){
'use strict';

module.exports = function () {

    function updateLogTable() {
        var datatable = $('#log-table').DataTable();

        var dateSearch = $('#log-date-from').val() + '_to_' + $('#log-date-to').val();
        datatable.columns(0).search(dateSearch);

        var userSearch = $('#user-filter-select').val() + '_with_action_' + $('#action-filter-select').val();
        datatable.columns(1).search(userSearch);

        datatable.draw();
    }

    $('.log-table-search-date').datepicker({
        format: "dd-mm-yyyy",
        language: Lang.get('dates.datepickerLocale')
    }).on('click', function () {
        $(this).val('');
        $(this).trigger('change');
    });

    $('.log-table-filter').on('change', function () {
        updateLogTable();
    });
};

},{}],49:[function(require,module,exports){
'use strict';

var TableManaged = (function () {

    var initTable = function initTable(tableId, pageLength, searchOption, indexColumnStartingSorted) {
        var table = $(tableId);

        var ordering = true;
        if (!indexColumnStartingSorted) indexColumnStartingSorted = 1;else if (indexColumnStartingSorted < 0) {
            ordering = false;indexColumnStartingSorted = 1;
        }

        var rows = table.find('tbody > tr');
        if (rows.length <= 1) {
            var skip = false;
            rows.find('td').each(function () {
                if ($(this).attr('colspan') > 0) skip = true;
            });
            if (skip) return;
        }

        var orderable = [];
        var i = 0;

        $('table' + tableId + ' > thead > tr > th').each(function () {
            if ($.parseJSON($(this).attr('data-sort')) == false) {
                orderable.push(i);
            }
            i++;
        });

        table.dataTable({
            "lengthMenu": [[25, 50, 100, -1], [25, 50, 100, "Alles"] // change per page values here
            ],
            // set the initial value
            "pageLength": pageLength = typeof pageLength !== 'undefined' ? pageLength : 25,
            "pagingType": "bootstrap_full_number",
            "ordering": ordering,
            "searching": pageLength = typeof searchOption !== 'undefined' ? searchOption : true,
            "language": {
                "sLengthMenu": "",
                "sZeroRecords": Lang.get('tables.NoResultsFound'),
                "sInfo": Lang.get('tables.sInfo'),
                "sInfoEmpty": Lang.get('tables.NoResultsFound'),
                "sInfoFiltered": Lang.get('tables.sInfoFiltered'),
                "sInfoPostFix": "",
                "sSearch": Lang.get('tables.sSearch'),
                "sEmptyTable": Lang.get('tables.NoResultsFound'),
                "sInfoThousands": ".",
                "oPaginate": {
                    "sFirst": Lang.get('tables.sFirst'),
                    "sLast": Lang.get('tables.sLast'),
                    "sNext": Lang.get('tables.sNext'),
                    "sPrevious": Lang.get('tables.sPrevious')
                }
            },
            "columnDefs": [{ // set default column settings
                'orderable': false,
                'targets': orderable
            }, {
                "searchable": false,
                "targets": orderable
            }],
            "order": [[indexColumnStartingSorted, "asc"]] // index of column to sort
        });

        var tableWrapper = jQuery('#content_items_index_wrapper');

        table.find('.group-checkable').change(function () {
            var set = jQuery(this).attr("data-set");
            var checked = jQuery(this).is(":checked");
            jQuery(set).each(function () {
                if (checked) {
                    $(this).attr("checked", true);
                    $(this).parents('tr').addClass("active");
                } else {
                    $(this).attr("checked", false);
                    $(this).parents('tr').removeClass("active");
                }
            });
            jQuery.uniform.update(set);
        });

        table.on('change', 'tbody tr .checkboxes', function () {
            $(this).parents('tr').toggleClass("active");
        });

        tableWrapper.find('.dataTables_length select').addClass("form-control input-xsmall input-inline"); // modify table per page dropdown
    };

    return {

        //main function to initiate the module
        init: function init(tableId, pagelength, searchOption, indexColumnStartingSorted) {
            if (!jQuery().dataTable) {
                return;
            }

            initTable(tableId, pagelength, searchOption, indexColumnStartingSorted);
        }
    };
})();

/* Default class modification */
$.extend($.fn.dataTableExt.oStdClasses, {
    "sFilterInput": "form-control input-xs input-inline pull-right"
});

$.fn.dataTableExt.sErrMode = 'throw';

module.exports = {
    TableManaged: TableManaged
};

},{}],50:[function(require,module,exports){
'use strict';

module.exports = function () {
    var Modal = require('../modals/modal');
    $(document).on('hidden', '.ajax-content-tab-reload', function () {
        var target = $(this).data('target');
        $(target).html('');
        $('#manage-taskables [href="' + target + '"]').trigger('click');
    });

    window.createTree = function (selector) {
        $(typeof selector !== 'undefined' ? selector : '.js-tree').jstree({
            'plugins': ["wholerow", "checkbox", "types"],
            conditionalselect: function conditionalselect(node) {
                if (node.type == 'task') {
                    return true;
                }
                this.toggle_node(node);
                return false;
            },
            "types": {
                "default": {
                    "icon": "fa fa-folder icon-state-warning icon-lg"
                },
                "task": {
                    "icon": "icon-docs font-green-haze"
                },
                "Category": {
                    "icon": ""
                }
            }
        }).on('changed.jstree', function (e, data) {
            $.ajax({
                method: 'POST',
                url: $(this).data('url'),
                beforeSend: function beforeSend() {
                    ajaxMessage.load();
                },
                data: { targetGroupId: coco.targetGroupId, taskableIds: data.selected },
                success: function success() {
                    ajaxMessage.finish();
                }
            });
        }).on('open_node.jstree', function (node) {
            var modal = new Modal('.modal');
            modal.resize();
        });
    };

    $(document).on('change', '#monitoring_questionnaire_id', function () {
        var value = $(this).val();

        if (value == '') {
            $('#monitoring_frequency').attr('required', false);
        } else {
            $('#monitoring_frequency').attr('required', true);
        }
    });
};

},{"../modals/modal":32}],51:[function(require,module,exports){
'use strict';

module.exports = function () {

    $(document).on('change', '.future-date-pick', function () {
        $('.start-at-date').prop("checked", true).parent().addClass('checked');
        $('.start-now').parent().removeClass('checked');
        $(this).datepicker('hide');
    });
};

},{}],52:[function(require,module,exports){
'use strict';

module.exports = function () {
    window.markTaskAsCompleted = function (task_id) {
        var taskElement = $('#task-' + task_id);
        if (taskElement.hasClass('trainingPreviewSmall')) {
            taskElement.removeClass('started').addClass('finished');
            taskElement.find('.exercise-thumbnail').attr('src', '/img/exericse-thumbnail-check.png');
        }

        var taskIcon = taskElement.find('.task-status-icon');
        var link = taskElement.find('.timeline-link');
        taskIcon.removeClass('fa-square-o font-blue');
        taskIcon.addClass('fa-check font-green');

        $('.overdue.timeline-item:not(:has(i.fa-square-o) )').delay(1000).fadeOut(1500);
        $('.overdue #task-' + task_id).delay(3000).fadeOut(1500);
    };

    $(document).on('testWasFinished', function (event, params) {
        markTaskAsCompleted(params.taskId);
    });

    $(document).on('exercisesWereFinished', function (event, params) {
        $.each(params.taskIds, function (index, taskId) {
            markTaskAsCompleted(taskId);
        });
    });
};

},{}],53:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('click', 'a.delete-element[data-target]', function (e) {
        var selector = $(this).attr('data-target');
        var deleteTimelineDay = $(this).attr('data-delete-timeline-item');
        var url = $(this).attr('href');

        bootbox.dialog({
            title: Lang.get('global.Confirm'),
            message: Lang.get('tasks.AreYouSureToDelete'),
            buttons: {
                success: {
                    label: Lang.get('global.Yes'),
                    className: "blue",
                    callback: function callback() {
                        $(selector).append('<i class="fa fa-spinner fa-spin grey"></i>');

                        $.ajax({
                            url: url,
                            type: 'DELETE',
                            success: function success(response) {
                                if (response) {
                                    $(selector).html(response).delay(4000).hide(500);
                                    var timeLineItem = $(selector).closest('.timeline-item');
                                    if (deleteTimelineDay == 'true' && timeLineItem.find('li:visible span.task-title').length == 0) {
                                        timeLineItem.delay(5000).hide(500);
                                    }
                                }
                            }
                        });
                    }
                },
                danger: {
                    label: Lang.get('global.No'),
                    className: "grey",
                    callback: function callback() {}
                }
            }
        });

        e.preventDefault();
    });
};

},{}],54:[function(require,module,exports){
'use strict';

module.exports = function () {

    $('#tree_2').on('changed.jstree', function (e, data) {
        var i,
            j,
            items = [];
        for (i = 0, j = data.selected.length; i < j; i++) {

            if (data.instance.get_node(data.selected[i]).data.jstree.type == "task") {
                items.push(data.instance.get_node(data.selected[i]).id);
            }
        }
        $('#tree-items').get(0).value = JSON.stringify(items);

        if (data.node.type == 'category') {
            $(this).jstree().open_node(data.node);
        }
    }).jstree({
        'plugins': ["wholerow", "checkbox", "types", "conditionalselect", 'search'],
        conditionalselect: function conditionalselect(node) {
            if (node.type == 'task') {
                return true;
            }
            this.toggle_node(node);
            return false;
        },
        'search': {
            'show_only_matches': true
        },

        "types": {
            "default": {
                "icon": "fa fa-folder icon-state-warning icon-lg"
            },
            "task": {
                "icon": "icon-docs font-green-haze"
            },
            "category": {
                "icon": ""
            }
        }
    });
};

},{}],55:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('submit', '#timetable-delete', function (e) {
        e.preventDefault();
        $.ajax({
            type: 'DELETE',
            'url': $(this).attr('action'),
            data: {
                view: $("#calendar").fullCalendar('getView').name,
                date: $('#calendar').fullCalendar('getDate').format()
            },
            success: function success() {
                location.reload();
            }
        });
    });

    $(document).on('change', '#activiteitenweger_activity_id', function (e) {
        var activityId = $(this).val();
        var activity = coco.activities.filter(function (activity) {
            return activity.id == activityId;
        })[0];

        if (activity) {
            var startTime = Date.parse($('#activiteitenweger_date').val().substr(0, 10).replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3") + ' ' + $('#start_datetime').val());
            var newFinishTime = new Date(startTime.getTime() + activity.duration * 60000);
            $('#end_datetime').val(newFinishTime.getHours() + ':' + (newFinishTime.getMinutes() < 10 ? '0' : '') + newFinishTime.getMinutes());
            $('input[name="primary_category_ids"]').val(activity.category_id);
            $('input[name="description"]').val(activity.description);
        }
    });
};

},{}],56:[function(require,module,exports){
'use strict';

module.exports = function () {
    var ProgramTask = require('../models/programTask');
    require('../scripts/draggableTraining')();

    $("#base-program-edit .trainingPreviewSmall").draggable(draggableTraining);
    $("#base-program-edit td.base-program-day").droppable({
        over: function over(event, ui) {
            if ($(this).hasClass('droppable-day')) {
                $(this).css('border', '4px dotted #91C400').css('background-color', '#91C400');
            }
        },
        out: function out(event, ui) {
            if ($(this).hasClass('droppable-day')) {
                $(this).css('border', '4px dotted #3598dc').css('background-color', '#3598dc');
            }
        },
        drop: function drop(event, ui) {
            var exerciseId = ui.draggable.data('exercise-id');
            var oldDate = ui.draggable.closest('td.coming-day').data('date');
            var newDate = $(this).data('date');
            var alreadyExists = $(this).find('div[data-exercise-id="' + exerciseId + '"]').length > 0;
            if (newDate != oldDate) {
                if (alreadyExists) {
                    var message = require('../../alerts/message');
                    message.show(true, $(this).closest('table').data('feedback-task-already-exists'));
                } else {
                    ProgramTask.createMany([ProgramTask.createByExerciseId(exerciseId, newDate)]);
                    if (!event.ctrlKey) {
                        ProgramTask.removeMany([ProgramTask.createByExerciseId(exerciseId, oldDate)]);
                    }
                }
            }
        }
    });

    $(document).on('click', "#base-program-edit .trainingPreviewSmall .close", function () {
        var date = $(this).closest('td').data('date');
        var exerciseId = $(this).closest('.trainingPreviewSmall').data('exercise-id');
        ProgramTask.removeMany([ProgramTask.createByExerciseId(exerciseId, date)]);
    });
};

},{"../../alerts/message":6,"../models/programTask":69,"../scripts/draggableTraining":71}],57:[function(require,module,exports){
'use strict';

module.exports = function () {

    require('./dragging')();
    var ProgramTask = require('../models/programTask');
    var ExerciseCheckbox = require('../models/exerciseCheckbox');

    $(document).on('click', '#base-program-edit .add-training-task', function () {
        var day = $(this).data('date');
        var exerciseId = $(this).data('exercise-id');

        var task = ProgramTask.createByExerciseId(exerciseId, day);
        if (this.checked) {
            ProgramTask.createMany([task]);
        } else {
            ProgramTask.removeMany([task]);
        }
    });

    $(document).on('click', '#base-program-edit .select-all', function () {
        var tasks = [];
        var exerciseId = $(this).data('exercise-id');

        $('table.trainingTable').find('th').each(function () {
            var day = $(this).data('day');
            tasks.push(ProgramTask.createByExerciseId(exerciseId, day));
        });

        if (this.checked) {
            ProgramTask.createMany(tasks);
        } else {
            ProgramTask.removeMany(tasks);
        }
    });

    // $(document).on('click', 'li.filter-for-category a', function () {
    //     $('li.filter-for-category a').removeClass('selected');
    //     $(this).addClass('selected');
    //
    //     $('#add-exercises')
    //         .DataTable()
    //         .draw();
    // });

    ExerciseCheckbox.updateAllSelectAllBoxes();

    $('.js-tree-training').jstree({
        'core': {
            'multiple': false,
            'themes': {
                'icons': false,
                'variant': 'large'
            }
        },
        'plugins': ["wholerow"]
    });
};

},{"../models/exerciseCheckbox":67,"../models/programTask":69,"./dragging":56}],58:[function(require,module,exports){
'use strict';

module.exports = function () {

    var ExerciseTask = require('../models/exerciseTask');

    var toggleDisableElement = function toggleDisableElement(parentElement, equation, element) {
        if (equation) {
            parentElement.find(element).addClass('disabledElement');
        } else {
            parentElement.find(element).removeClass('disabledElement');
        }
    };

    $(document).on('mouseenter', '.training-table-day', function () {
        var today = Date.today();
        var nextDay = new Date.parse($(this).data('date-js')).addDays(1);
        var previousDay = new Date.parse($(this).data('date-js')).addDays(-1);

        if ($(this).children('.trainingPreviewSmall ').length > 0) {
            $(this).find('.copy-day').show();
        }

        toggleDisableElement($(this), today.compareTo(nextDay) > 0 && today.compareTo(previousDay) > 0, '.copy-day');
        toggleDisableElement($(this), today.compareTo(nextDay) > 0, '.copy-next');
        toggleDisableElement($(this), today.compareTo(previousDay) > 0, '.copy-previous');
    }).on('mouseleave', '.training-table-day', function () {
        $(this).find('.copy-day').hide();
    });

    $(document).on('click', '.copy-day a', function () {
        var column = $(this).parents('td.training-table-day');
        column.find('.copy-progress-icon').removeClass('fa fa-check').addClass('fa fa-spinner fa-spin').css({ 'display': 'inline-block', 'color': 'gray', 'opacity': 100 });
        var toDate = new Date.parse(column.data('date-js'));
        if ($(this).hasClass('copy-previous')) {
            toDate.addDays(-1);
        } else {
            toDate.addDays(1);
        }
        var tasks = [];
        column.find('.trainingPreviewSmall').each(function () {
            if (!$(this).hasClass('patient-initiated')) {
                tasks.push(ExerciseTask.createByExerciseId($(this).data('exercise-id'), toDate.toString('d-M-yyyy')));
            }
        });
        ExerciseTask.createMany(tasks).done(function () {
            column.find('.copy-progress-icon').removeClass('fa-spinner fa-spin').addClass('fa fa-check').css({ 'color': '#91C400' }).delay(3000).fadeOut(1000);
        });
    });
};

},{"../models/exerciseTask":68}],59:[function(require,module,exports){
'use strict';

module.exports = function () {

    require('../scripts/draggableTraining')();
    require('../scripts/droppableDay')();
    var ExerciseTask = require('../models/exerciseTask');

    $('td.coming-day').sortable({
        update: function update() {
            var order = $(this).find('.trainingPreviewSmall').map(function (i) {
                return $(this).data('task-id');
            });
            $.post('/tasks/exercises/order', { order: order.toArray() });
        }
    }).disableSelection();
};

},{"../models/exerciseTask":68,"../scripts/draggableTraining":71,"../scripts/droppableDay":72}],60:[function(require,module,exports){
'use strict';

module.exports = function () {

    if (typeof window.exerciseInstructionsAreLoaded !== typeof undefined) {
        return;
    }
    window.exerciseInstructionsAreLoaded = true;

    $(document).on('click', 'a[data-toggle="mass-assign-instructions"]', function (e) {
        var tableRow = $(this).parents('tr');
        var exerciseId = tableRow.attr('data-exercise-id');

        var sets = tableRow.find('select.sets').val();
        var repetitions = tableRow.find('select.repetitions').val();
        var time = tableRow.find('select.time').val();
        var instructions = tableRow.find('input.instructions').val();
        var withHelp = tableRow.find('input.hidden-input').val();

        bootbox.dialog({
            title: Lang.get('global.Confirm'),
            message: Lang.get('global.ValuesWillBeOverridden'),
            buttons: {
                success: {
                    label: Lang.get('global.Yes'),
                    className: "green",
                    callback: function callback() {
                        $('#edit-exercise-instructions-form').find('tr[data-exercise-id="' + exerciseId + '"]').not('tr[data-disabled="true"]').each(function (index) {

                            $(this).addClass("success").delay(2000).queue(function (next) {
                                $(this).removeClass("success");
                                next();
                            });

                            $(this).find('select.sets').val(sets);
                            $(this).find('select.repetitions').val(repetitions);
                            $(this).find('select.time').val(time);
                            $(this).find('input.instructions').val(instructions);
                            $(this).find('input.hidden-input').val(withHelp);

                            if (withHelp == 1) {
                                $(this).find('img.toggle-help').attr('src', '/img/help-with-third-on.png');
                            } else {
                                $(this).find('img.toggle-help').attr('src', '/img/help-with-third-off.png');
                            }
                        });
                    }
                },
                danger: {
                    label: Lang.get('global.No'),
                    className: "red",
                    callback: function callback() {}
                }
            }
        });
        e.preventDefault();
    });

    $(document).on('click', 'a.toggle-exercise-with-help', function (e) {
        e.preventDefault();
        var link = $(this);
        var image = link.find('img.toggle-help');

        if (image.attr('src') == '/img/help-with-third-off.png') {
            image.attr('src', '/img/help-with-third-on.png');
            link.parent('td').find('.hidden-input').val(1);
        } else {
            image.attr('src', '/img/help-with-third-off.png');
            link.parent('td').find('.hidden-input').val(0);
        }
    });
};

},{}],61:[function(require,module,exports){
'use strict';

module.exports = function () {

    var ExerciseTask = require('../models/exerciseTask');
    var ExerciseCheckbox = require('../models/exerciseCheckbox');

    $(document).on('click', '#patient-training-edit .add-training-task', function (e) {
        var date = $(this).data('date');
        var exerciseId = $(this).data('exercise-id');

        var task = ExerciseTask.createByExerciseId(exerciseId, date);

        var origHeight = getPageHeight();
        var origY = getCurrentScrollPostion();

        if (this.checked) {
            ExerciseTask.createMany([task], origY, origHeight);
        } else {
            ExerciseTask.removeMany([task], origY, origHeight);
        }
    });

    $(document).on('click', '#patient-training-edit .select-all', function () {
        var origHeight = getPageHeight();
        var origY = getCurrentScrollPostion();

        var tasks = [];
        var exerciseId = $(this).data('exercise-id');

        $('table.trainingTable').find('th').each(function () {
            var date = $(this).data('date');
            tasks.push(ExerciseTask.createByExerciseId(exerciseId, date));
        });

        if (this.checked) {
            ExerciseTask.createMany(tasks, origY, origHeight);
        } else {
            ExerciseTask.removeMany(tasks, origY, origHeight);
        }
    });

    ExerciseCheckbox.updateAllSelectAllBoxes();
};

},{"../models/exerciseCheckbox":67,"../models/exerciseTask":68}],62:[function(require,module,exports){
'use strict';

module.exports = function () {

    $(document).on('click', '#toggle-help-exercises-available', function () {
        $('#notCompletedTasks').find('div.trainingPreviewSmall[needs-help]').each(function () {
            if ($(this).prop('checked')) {
                $(this).removeClass('disabledElement');
            } else {
                $(this).addClass('disabledElement');
            }
        });
    });

    $(document).on('click', 'input.save-in-session:checkbox', function () {
        $.ajax({
            method: 'POST',
            url: '/session/put',
            data: { key: $(this).data('key'), value: $(this).prop('checked') }
        });
    });

    $(document).on('change', '.mark-as-completed-checkbox', function () {
        if (this.checked) {
            $('#markAsCompletedBtn').removeClass('disabled');
        } else {
            if ($('.mark-as-completed-checkbox:checked').length == 0) {
                $('#markAsCompletedBtn').addClass('disabled');
            }
        }
    });
};

},{}],63:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('click', '#submit-skip-reason-button', function (e) {
        var form = $(this).closest('form');
        var modal = $('#show-task').modal();

        if (form.data('ajax') == 0) {
            return;
        }
        e.preventDefault();

        $.ajax({
            url: form.attr('action'),
            method: 'PUT',
            data: form.serialize() + '&' + $('#exercises-task-show').serialize() + '&action=skip',
            success: function success(indexPage) {

                $('#show-skip-exercise-modal').modal('hide');
                $('#large-modal').modal('hide');

                if (coco.trainings == true) {
                    modal.modal('hide');
                } else {
                    modal.html(indexPage);
                    modal.resize();
                    Metronic.initAjax();
                }
            }
        });
    });

    $(document).on('click', '#exerciseDoneBtn', function (e) {
        var modal = $(this).closest('.modal');
        var form = $(this).closest('form');
        if (form.data('ajax') == 0) {
            return;
        }
        e.preventDefault();
        $.ajax({
            url: form.attr('action'),
            method: 'PUT',
            data: form.serialize() + '&action=finish',
            success: function success(indexPage) {
                markTaskAsCompleted(form.data('task-id'));
                $('#show-task').html(indexPage);
                $('#large-modal').modal('hide');
                Metronic.initAjax();
            }
        });
    });

    $(document).on('click', '#skipExercise', function (e) {
        e.preventDefault();
        $('#show-skip-exercise-modal').modal('show');

        //reset the form
        $('#skip-reason-textarea').val('');
        $('#skip-exercise-form').attr('action', "/tasks/exercises/" + $(this).data('task-id'));
    });
};

},{}],64:[function(require,module,exports){
'use strict';

module.exports = function () {

    var ExerciseTask = require('../models/exerciseTask');

    $(document).on('click', '#stopButton', function () {
        $('.modal').modal('hide');
    });

    $(document).on('click', '.increment-comments', function () {
        incrementComment($(this).attr('data-exercise-id'), 1);
    });

    var incrementComment = function incrementComment(exerciseId, incrementValue) {
        var messageElement = $('#comments-count-exercise-' + exerciseId);
        var originalCount = parseInt(messageElement.text(), 10) || 0;
        messageElement.html('' + (originalCount + incrementValue) + '<i class="fa fa-comment fa-lg exercise-comment"></i>');
        var previews = $('.trainingPreviewSmall[data-exercise-id="' + exerciseId + '"]');
        var previewMessageElements = previews.find('span.preview-message-element');
        if (originalCount == 0) {
            var message = coco.comment;
        } else {
            var message = coco.comments;
        }
        previewMessageElements.html('<i class="fa fa-comment number-of-comments" title="' + (originalCount + incrementValue) + ' ' + message + '" style="font-size: 10px; position: relative; top: 0px;"></i>');
    };

    var params = require('../../url/params');
    $(document).one("ajaxStop", function () {
        var exerciseId = params.getParam('show-exercise-modal-comments');
        if (typeof exerciseId !== typeof undefined) {
            var dataTarget = $("#show-exercise");
            $.ajax({
                method: 'GET',
                url: '/patients/exercise-information/' + exerciseId,
                data: {
                    userId: coco.userId
                },
                success: function success(data) {
                    dataTarget.html(data).modal('show');
                    $('.nav-tabs a[href="#exercise_tab_2"]').click();
                }
            });
        }
    });

    $(document).on('click', 'li.filter-for-category a', function () {
        $('li.filter-for-category a').removeClass('selected');
        $(this).addClass('selected');

        $('#add-exercises').DataTable().draw();
    });

    var exerciseSearchId = null;
    $(document).on('keyup', 'input.exercise-search', function () {
        clearTimeout(exerciseSearchId);
        exerciseSearchId = setTimeout(function () {
            $('#add-exercises').DataTable().draw();
        }, 500);
    });

    $(document).on('change', '#exercise-sort', function () {
        $('#add-exercises').DataTable().draw();
    });

    $(document).on('change', 'input.subCategory', function () {
        $('#add-exercises').DataTable().draw();
    });

    $(document).on('click', '#remove-this-week-tasks', function (e) {
        var message = $(this).data('message');
        var title = $(this).data('title');

        bootbox.dialog({
            title: title,
            message: message,
            buttons: {
                success: {
                    label: Lang.get('global.Yes'),
                    className: "red",
                    callback: function callback() {
                        var tasks = [];
                        $('table.trainingTable').find('td.coming-day').find('div.trainingPreviewSmall').each(function () {
                            var task = ExerciseTask.createByExerciseId($(this).attr('data-exercise-id'), $(this).closest('td').attr('data-date'));
                            tasks.push(task);
                        });
                        ExerciseTask.removeMany(tasks);
                    }
                },
                danger2: {
                    label: "Ja, van deze én opvolgende weken",
                    className: "grey",
                    callback: function callback() {
                        $.ajax({
                            url: '/patients/' + coco.patientId + '/tabs/training/clearFuture',
                            type: 'DELETE',
                            data: { 'weekDate': coco.weekDate },
                            success: function success(result) {
                                $('table.trainingTable').find('td.coming-day').find('div.trainingPreviewSmall').remove();
                                $('.add-training-task').removeAttr('checked');
                                $('.checker span.checked').removeClass('checked');
                            }
                        });
                    }
                },
                danger: {
                    label: Lang.get('global.No'),
                    className: "grey",
                    callback: function callback() {}
                }
            }
        });
        e.preventDefault();
    });

    $(document).on('click', '.delete-comment', function (e) {
        var deleteButton = $(this);
        e.preventDefault();
        bootbox.dialog({
            message: Lang.get('global.AreYouSure'),
            title: Lang.get('global.Confirm'),
            buttons: {
                danger: {
                    label: Lang.get('global.No'),
                    className: "btn-link",
                    callback: function callback() {}
                },
                success: {
                    label: Lang.get('global.Yes'),
                    className: "btn blue",
                    callback: function callback() {
                        $.ajax({
                            method: 'DELETE',
                            url: '/remove-comment/' + deleteButton.data('instance-id'),
                            success: function success() {
                                deleteButton.parents('.comment-block').fadeOut(1000, function () {
                                    $(this).remove();
                                });
                                incrementComment(deleteButton.data('exerciseId'), -1);
                            }
                        });
                    }
                }
            }
        });
    });
};

},{"../../url/params":76,"../models/exerciseTask":68}],65:[function(require,module,exports){
'use strict';

module.exports = function () {

    var ExerciseTask = require('../models/exerciseTask');

    $(document).on('click', "#patient-training-edit .trainingPreviewSmall .close", function () {
        var date = $(this).closest('td.coming-day').data('date');
        var exerciseId = $(this).closest('.trainingPreviewSmall').data('exercise-id');
        ExerciseTask.removeMany([ExerciseTask.createByExerciseId(exerciseId, date)]);
    });
};

},{"../models/exerciseTask":68}],66:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var ExerciseCheckbox = require('./exerciseCheckbox');

var Exercise = (function () {
    function Exercise(task) {
        _classCallCheck(this, Exercise);

        this.checkbox = ExerciseCheckbox.createByTask(task);
        this.trainingPreview = $('td#date-' + task.date + ' div.trainingPreviewSmall[data-exercise-id="' + task.exerciseId + '"]');
    }

    _createClass(Exercise, [{
        key: 'remove',
        value: function remove() {
            this.trainingPreview.addClass('marked');
            this.checkbox.turnOff();

            $(this.trainingPreview).remove();
            Exercise.toggleButtonsIfNeeded();
        }
    }, {
        key: 'getTaskId',
        value: function getTaskId() {
            return this.trainingPreview.data('task-id');
        }
    }], [{
        key: 'toggleButtonsIfNeeded',
        value: function toggleButtonsIfNeeded() {
            var editInstructionsButton = $('button#linkEditInstructions');
            var removeWeekButton = $('a#remove-this-week-tasks');
            if ($('table.trainingTable').find('td.coming-day').find(".trainingPreviewSmall").not('.marked').length == 0) {
                editInstructionsButton.hide();
                removeWeekButton.hide();
            } else {
                editInstructionsButton.show();
                removeWeekButton.show();
            }
        }
    }, {
        key: 'create',
        value: function create(task, previewHtml) {
            var column = $('td.coming-day[data-date="' + task.date + '"]');
            column.append($.parseHTML(previewHtml));
            var exercise = new Exercise(task);
            exercise.checkbox.turnOn();

            Exercise.toggleButtonsIfNeeded();

            return exercise;
        }
    }]);

    return Exercise;
})();

exports['default'] = Exercise;
module.exports = exports['default'];

},{"./exerciseCheckbox":67}],67:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var ExerciseCheckbox = (function () {
    function ExerciseCheckbox() {
        _classCallCheck(this, ExerciseCheckbox);
    }

    _createClass(ExerciseCheckbox, [{
        key: 'turnOff',
        value: function turnOff() {
            this.checkbox.prop('checked', false);
            this.checkbox.parent().removeClass('checked');
            this.updateSelectAllBox();
        }
    }, {
        key: 'turnOn',
        value: function turnOn() {
            this.checkbox.prop('checked', true);
            this.checkbox.parent().addClass('checked');
            this.updateSelectAllBox();
        }
    }, {
        key: 'updateSelectAllBox',
        value: function updateSelectAllBox() {
            var allCheckboxes = this.checkbox.parents('tr').find('.add-training-task[disabled != "disabled"]');
            var checkedBoxes = this.checkbox.parents('tr').find('.add-training-task[disabled != "disabled"]:checked');

            if (allCheckboxes.length == checkedBoxes.length) {
                this.selectAllCheckbox.parent().addClass('checked');
                this.selectAllCheckbox.prop('checked', true);
            } else {
                this.selectAllCheckbox.parent().removeClass('checked');
                this.selectAllCheckbox.prop('checked', false);
            }
        }
    }], [{
        key: 'createByTask',
        value: function createByTask(task) {
            var checkbox = new ExerciseCheckbox();
            checkbox.checkbox = $('input.add-training-task[data-exercise-id="' + task.exerciseId + '"][data-date="' + task.date + '"]');
            checkbox.selectAllCheckbox = checkbox.checkbox.closest('tr').find('.select-all');
            return checkbox;
        }
    }, {
        key: 'updateAllSelectAllBoxes',
        value: function updateAllSelectAllBoxes() {
            $('input.select-all').each(function () {
                var checkbox = new ExerciseCheckbox();
                checkbox.checkbox = $(this);
                checkbox.selectAllCheckbox = $(this);
                checkbox.updateSelectAllBox();
            });
        }
    }]);

    return ExerciseCheckbox;
})();

exports['default'] = ExerciseCheckbox;
module.exports = exports['default'];

},{}],68:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var Exercise = require('./exercise');

var ExerciseTask = (function () {
    function ExerciseTask(exerciseId, date) {
        _classCallCheck(this, ExerciseTask);

        this.exerciseId = exerciseId;
        this.date = date;
    }

    _createClass(ExerciseTask, null, [{
        key: 'createByExerciseId',
        value: function createByExerciseId(exerciseId, date) {
            return new ExerciseTask(exerciseId, date);
        }
    }, {
        key: 'createMany',
        value: function createMany(tasks) {
            var origY = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];
            var origHeight = arguments.length <= 2 || arguments[2] === undefined ? 0 : arguments[2];

            var box = $(':focus');
            ajaxIcon.load(box);
            return $.ajax({
                type: 'POST',
                url: '/tasks/exercises',
                data: {
                    patientId: coco.patientId,
                    week: coco.week,
                    tasks: tasks
                },
                dataType: 'json',
                success: function success(addedTasks) {
                    ajaxIcon.finish(box);
                    if (addedTasks.warning) {
                        var message = require('../../alerts/message');
                        message.show('warning', addedTasks.warning);
                    } else {
                        addedTasks.forEach(function (addedTask) {
                            var task = ExerciseTask.createByExerciseId(addedTask.exerciseId, addedTask.date);
                            Exercise.create(task, addedTask.taskHtml);
                        });
                    }
                    if (origY > 0) {
                        var currentHeight = getPageHeight();
                        var heightDifference = currentHeight - origHeight;
                        $(window).scrollTop(origY + heightDifference);
                    }
                }
            });
        }
    }, {
        key: 'removeMany',
        value: function removeMany(tasks) {
            var origY = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];
            var origHeight = arguments.length <= 2 || arguments[2] === undefined ? 0 : arguments[2];

            var box = $(':focus');
            ajaxIcon.load(box);

            var taskIds = [];
            tasks.forEach(function (task) {
                var exercise = new Exercise(task);
                exercise.remove();
                taskIds.push(exercise.getTaskId());
            });

            if (origY > 0) {
                var currentHeight = getPageHeight();
                var heightDifference = origHeight - currentHeight;
                $(window).scrollTop(origY - heightDifference);
            }

            $.ajax({
                type: 'DELETE',
                url: '/tasks/exercises/deleteMany',
                data: {
                    taskIds: taskIds
                },
                success: function success(deletedTasks) {
                    ajaxIcon.finish(box);
                }
            });
        }
    }]);

    return ExerciseTask;
})();

exports['default'] = ExerciseTask;
module.exports = exports['default'];

},{"../../alerts/message":6,"./exercise":66}],69:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var Exercise = require('./exercise');

var ProgramTask = (function () {
    function ProgramTask(exerciseId, day) {
        _classCallCheck(this, ProgramTask);

        this.exerciseId = exerciseId;
        this.date = day;
    }

    _createClass(ProgramTask, null, [{
        key: 'createByExerciseId',
        value: function createByExerciseId(exerciseId, day) {
            return new ProgramTask(exerciseId, day);
        }
    }, {
        key: 'createMany',
        value: function createMany(tasks) {
            var box = $(':focus');
            ajaxIcon.load(box);
            $.ajax({
                type: 'POST',
                url: '/program-tasks/create-many/' + coco.baseProgramId,
                data: {
                    week: coco.week,
                    tasks: tasks
                },
                dataType: 'json',
                success: function success(addedTasks) {
                    ajaxIcon.finish(box);
                    addedTasks.forEach(function (addedTask) {
                        var task = ProgramTask.createByExerciseId(addedTask.exerciseId, addedTask.date);
                        Exercise.create(task, addedTask.taskHtml);
                    });
                }
            });
        }
    }, {
        key: 'removeMany',
        value: function removeMany(tasks) {
            var box = $(':focus');
            ajaxIcon.load(box);

            tasks.forEach(function (task) {
                var exercise = new Exercise(task);
                exercise.remove();
            });

            $.ajax({
                type: 'DELETE',
                url: '/program-tasks/delete-many/' + coco.baseProgramId,
                data: {
                    week: coco.week,
                    tasks: tasks
                },
                success: function success() {
                    ajaxIcon.finish(box);
                }
            });
        }
    }]);

    return ProgramTask;
})();

exports['default'] = ProgramTask;
module.exports = exports['default'];

},{"./exercise":66}],70:[function(require,module,exports){
'use strict';

module.exports = function () {

    //Open modal when the parameter "show-exercise-modal-comments" is in the url
    var params = require('../../url/params');

    var exerciseId = params.getParam('show-exercise-modal-comments');

    if (typeof exerciseId !== typeof undefined) {
        var dataTarget = $("#show-task");
        $.ajax({
            method: 'GET',
            url: '/additionaltraining/' + exerciseId,
            data: {
                userId: coco.userId
            },
            success: function success(data) {
                dataTarget.html(data).modal('show');
                $('.nav-tabs a[href="#exercise_tab_2"]').click();
            }
        });
    }
    $(document).on('show.bs.collapse', '.ajax-accordion', function (e) {
        var target = $(e.target);
        if (!target.hasClass('empty')) {
            return;
        }
        target.removeClass('empty');
        $.get(target.data('url'), function (responseHtml) {
            target.find('.panel-body').html(responseHtml);
        });
    });
};

},{"../../url/params":76}],71:[function(require,module,exports){
'use strict';

module.exports = function () {
    window.draggableTraining = {
        connectToSortable: 'td.coming-day',
        revert: 'invalid',
        addClasses: false,
        helper: 'clone',
        containment: 'frame',
        start: function start(event, ui) {
            ui.helper.addClass("draggable");

            //Set the width of the draggable object to the same as the original div
            ui.helper.css("width", $(this).css('width'));
            ui.helper.css("height", $(this).css('height'));
            var comingDays = $('td.coming-day');
            //Color the valid and invalid columns to drop in
            var containingExercise = comingDays.has('.trainingPreviewSmall[data-exercise-id="' + $(this).data('exercise-id') + '"]');

            //The valid columns
            comingDays.not($(this).parents('td.coming-day')).not(containingExercise).css('border', '4px dotted #3598dc').css('background-color', '#3598dc').addClass('droppable-day').fadeTo(10, 0.1);

            //The invalid columns, the not-allowed cursor does not work because the pointer-events are set to none...
            containingExercise.not($(this).parents('td.coming-day')).css('background-color', 'gray').fadeTo(0, 0.1).css('pointer-events', 'none').css('cursor', 'not-allowed');
        },

        drag: function drag(event, ui) {
            //Show the original preview when the control key is pressed
            if (event.ctrlKey) {
                $(this).fadeTo(0, 1);
            } else {
                $(this).fadeTo(0, 0);
            }
        },
        stop: function stop(event, ui) {
            //Reset everything to the defaul again.
            ui.helper.removeClass("draggable");
            $("td.coming-day").not($(this).parents('td')).css('border', '').css('background-color', '').css('cursor', '').fadeTo(10, 1).css('pointer-events', '').removeClass('droppable-day');

            $(this).fadeTo(0, 1);
        }
    };
};

},{}],72:[function(require,module,exports){
'use strict';

module.exports = function () {
    var ExerciseTask = require('../models/exerciseTask');

    window.droppableDay = {

        over: function over(event, ui) {
            if ($(this).hasClass('droppable-day')) {
                $(this).css('border', '4px dotted #91C400').css('background-color', '#91C400');
            }
        },

        out: function out(event, ui) {
            if ($(this).hasClass('droppable-day')) {
                $(this).css('border', '4px dotted #3598dc').css('background-color', '#3598dc');
            }
        },

        drop: function drop(event, ui) {
            var exerciseId = ui.draggable.data('exercise-id');
            var oldDate = ui.draggable.closest('td.coming-day').data('date');
            var newDate = $(this).data('date');
            var alreadyExists = $(this).find('div[data-exercise-id="' + exerciseId + '"]').length > 0;
            if (newDate != oldDate) {
                if (alreadyExists) {
                    var message = require('../../alerts/message');
                    message.show(true, $(this).closest('table').data('feedback-task-already-exists'));
                } else {
                    ExerciseTask.createMany([ExerciseTask.createByExerciseId(exerciseId, newDate)]);
                    if (!event.ctrlKey) {
                        ExerciseTask.removeMany([ExerciseTask.createByExerciseId(exerciseId, oldDate)]);
                    }
                }
            }
        }
    };
};

},{"../../alerts/message":6,"../models/exerciseTask":68}],73:[function(require,module,exports){
'use strict';

module.exports = function () {

    $('.js-tree-training').on('open_node.jstree', function () {
        hideCategories(showedCategories);
    });

    Metronic.initAjax();

    var params = require('../../url/params');
    var ExerciseCheckbox = require('../models/exerciseCheckbox');
    if (!$.fn.DataTable.isDataTable('#add-exercises')) {
        $('#add-exercises').DataTable({
            "processing": false,
            "serverSide": true,
            "ajax": {
                'url': '/patient-table/' + coco.patientId + '?weekDate=' + coco.weekDate,
                'data': function data(d) {
                    var selectedSearch = {};
                    $("input.subCategory:checked").each(function () {
                        var name = $(this).prop('name');

                        if (selectedSearch.hasOwnProperty(name)) {
                            selectedSearch[name].push($(this).val());
                        } else {
                            selectedSearch[name] = [$(this).val()];
                        }
                    });

                    d.search.value = $('input.exercise-search').val();
                    d.sort = $('#exercise-sort').val();

                    d.searchCategories = selectedSearch;
                    d.category = $('li.filter-for-category a.selected').parents('li.filter-for-category').data('category-id');
                }
            },
            "language": {
                "sLengthMenu": "",
                "sZeroRecords": Lang.get('tables.NoResultsFound'),
                "sInfo": Lang.get('tables.sInfo'),
                "sInfoEmpty": Lang.get('tables.NoResultsFound'),
                "sInfoFiltered": Lang.get('tables.sInfoFiltered'),
                "sInfoPostFix": "",
                "sSearch": Lang.get('tables.sSearch'),
                "sEmptyTable": Lang.get('tables.NoResultsFound'),
                "sInfoThousands": ".",
                "oPaginate": {
                    "sFirst": Lang.get('tables.sFirst'),
                    "sLast": Lang.get('tables.sLast'),
                    "sNext": Lang.get('tables.sNext'),
                    "sPrevious": Lang.get('tables.sPrevious')
                }
            },
            "columnDefs": [{
                "targets": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                "sortable": false
            }]
        }).on('draw', function (e) {
            var json = $('#add-exercises').DataTable().ajax.json();
            ExerciseCheckbox.updateAllSelectAllBoxes();
        }).on('init', function () {
            var filterCategory = decodeURIComponent(params.getParam('filter-category'));
            if (typeof filterCategory !== typeof undefined) {
                setTimeout(function () {
                    $('li.filter-for-category[data-category-name="' + filterCategory + '"] a').trigger('click');
                }, 100);
            }
            $('.dataTables_filter').hide();
        });
    }
    dragDropTrainingPreviews();

    $('.js-tree-training').jstree({
        'core': {
            'multiple': false,
            'themes': {
                'icons': false,
                'variant': 'large'
            }
        },
        'plugins': ["wholerow"]
    });

    var Summernote = require('../../forms/summernote.js').Summernote();
    Summernote.init();
};

},{"../../forms/summernote.js":18,"../../url/params":76,"../models/exerciseCheckbox":67}],74:[function(require,module,exports){
"use strict";

module.exports = function () {
    var time = 1;
    var running = false;

    window.updateStopwatch = function () {
        var canvas = document.getElementById("stopwatch");
        if (canvas == null) {
            // stopwatch no longer visible
            running = false;
            time = 1;
            window.clearInterval(window.stopwatchId);
            return;
        }
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        var x = 17;
        var y = 24;
        var radius = 10;
        var startAngle = 1.5 * Math.PI;
        var endAngle = (time % 60 / 60 * 2 - 0.5) * Math.PI;

        ctx.font = "10px Arial";
        ctx.fillText(transformTime(time % 60), 11, 27);
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(x, y, radius, startAngle, endAngle, false);
        ctx.lineWidth = 4;
        ctx.strokeStyle = '#d84a38';
        ctx.stroke();
        time++;

        if (time % 60 == 1) {
            $('#big-stopwatch').after('<li class="pull-right small-stopwatch"> <img src="/images/stopwatch_small.png"></li>');
        }
        if (time % 900 == 0) {
            running = false;
            $('.small-stopwatch').remove();
        }
    };

    window.transformTime = function (time) {
        return time;
        //return ("0" + Math.floor(time / 5) * 5).slice(-2);
    };

    $(document).on('click', 'canvas#stopwatch', function (e) {
        if (running) {
            window.clearInterval(window.stopwatchId);
            running = false;
        } else {
            window.stopwatchId = window.setInterval("updateStopwatch()", 1000);
            running = true;
        }
    });

    $(document).on('dblclick', 'canvas#stopwatch', function (e) {
        time = 1;
        updateStopwatch();
        $('.small-stopwatch').remove();
        window.clearInterval(window.stopwatchId);
        window.stopwatchId = window.setInterval("updateStopwatch()", 1000);
        running = true;
    });
};

},{}],75:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).ready(function () {
        $('.navbar-nav li[data-base-url="' + location.pathname.split("/")[1] + '"]').addClass('active');
        $('.navbar-nav li[data-base-url="' + location.pathname.split("/")[1] + '"]').parentsUntil("menu-dropdown").addClass('active');
    });
};

},{}],76:[function(require,module,exports){
'use strict';

function getParam(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
}

module.exports = {
    getParam: getParam
};

},{}],77:[function(require,module,exports){
'use strict';

module.exports = function () {
    var Modal = require('../modals/modal');

    $(document).on('change', 'select[select-url]', function (e) {
        window.location = this.value;
    });

    function restoreForm(params) {
        $.each(params, function () {
            var element = $('[name="' + this.name + '"]');
            if (typeof element[0] !== typeof undefined && (element[0].type == 'checkbox' || element[0].type == 'radio')) {
                element = $('[name="' + this.name + '"][value="' + this.value + '"]');
                element.attr("checked", this.value == element.val());
            } else {
                $('[name="' + this.name + '"]').val(this.value);
            }
        });
    }

    $(document).on('change', 'select[data-update-ajax]', function (e) {
        var targetSelector = $(this).attr('data-target');
        var value = $(this).val();
        var params = $(this).parents('form').serializeArray();
        $.ajax({
            url: $(this).data('url'),
            data: params,
            type: 'GET',
            success: function success(response) {
                $(targetSelector).replaceWith(response);
                restoreForm(params);
                new Modal($('.modal:visible')).resize();

                var Summernote = require('../forms/summernote.js').Summernote();
                Summernote.init();
            }
        });
    });

    $(document).on('click', '.clickable-ajax[data-url]', function () {
        var modal = new Modal($(this).attr('data-target'));
        modal.load($(this).data('url'));
    });
};

},{"../forms/summernote.js":18,"../modals/modal":32}],78:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('hover', '[data-toggle-element]', function () {
        var element = $(this).attr('data-toggle-element');
        $(this).find(element).toggle();
    });
};

},{}],79:[function(require,module,exports){
'use strict';

module.exports = function () {
    $(document).on('hover', '.toggle-close-button', function () {
        $(this).find('.close').each(function () {
            if ($(this).css("visibility") == 'hidden') {
                if ($(this).parents('td.coming-day').length > 0) {
                    $(this).css("visibility", 'visible');
                }
            } else {
                $(this).css("visibility", 'hidden');
            }
        });
    });
};

},{}]},{},[26]);
